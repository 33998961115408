import React, { useEffect, useState, useRef } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NewGroupTimeline from "./NewGroupTimeLine";
import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  Button,
  TextField,
  OutlinedInput,
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { handleSpringDataMutationRequest } from "../../api";
import moment from "moment";
import MockLoaderTable from "../Folios/ViewManagePayments/MockLoaderTable";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import GroupRightDrawer from "../InventoryCalendar/Views/GroupRightDrawer";

// Update Hello component to accept hotelId, hotelCurrency, and slotType as props
function NewGroup({ bookingData }) {
  const [expanded, setExpanded] = useState(false);
  const [today, setToday] = useState(new Date());
  const [dateRange, setdateRange] = useState([]);
  const [occupancyData, setOccupancyData] = useState({});
  const [myloader, setmyloader] = useState(false);
  const prevTodayRef = useRef();
  const [searchTerm, setsearchTerm] = useState("");
  const [activeHotels, setactiveHotels] = useState([]);
  const [hotelIds, sethotelIds] = useState([]);
  const [hotelNames, sethotelNames] = useState([]);
  const [selectedHotels, setSelectedHotels] = useState([]);

  const isAdmin =
    localStorage.getItem("isAdmin") === "true" ||
    localStorage.getItem("isAdmin") === true
      ? true
      : false;

  const isAllSelected = selectedHotels.length === hotelIds.length;

  const handleChanges = (event) => {
    const value = event.target.value;

    if (value.includes("all")) {
      // Select or deselect all based on current state
      setSelectedHotels(isAllSelected ? [] : hotelIds);
    } else {
      setSelectedHotels(value);
    }
  };

  const renderValue = (selected) => {
    if (selected.length === hotelIds.length) {
      return "All selected";
    }
    return selected
      .map((hotelId) => hotelNames[hotelIds.indexOf(hotelId)])
      .join(", ");
  };
  useEffect(() => {
    if (bookingData.length > 0) {
      setactiveHotels(
        bookingData?.filter((items) => items.status === "ACTIVE")
      );
      sethotelIds(
        bookingData
          ?.filter((items) => items.status === "ACTIVE")
          ?.map((item) => item.hotelId)
      );
      sethotelNames(
        bookingData
          ?.filter((items) => items.status === "ACTIVE")
          ?.map((item) => item.hotelName)
      );

      setSelectedHotels(
        bookingData
          ?.filter((items) => items.status === "ACTIVE")
          ?.map((item) => item.hotelId)
      );
    }
  }, [bookingData]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getOccupancyColor = (occupancy) => {
    if (occupancy === "Sold out") return "#f87171";
    const rate = parseInt(occupancy);
    if (rate >= 80) return "#fbbf24";
    if (rate >= 60) return "#fde68a";
    return "#a5f3fc";
  };

  useEffect(() => {
    if (hotelIds?.length > 0) {
      if (
        prevTodayRef.current &&
        prevTodayRef.current.getTime() === today.getTime()
      ) {
        return;
      }

      getAllData();
      prevTodayRef.current = today;
    }
  }, [today, hotelIds]);

  const getNextDates = (daysCount, res) => {
    const dates = [];
    const updatedOccupancyData = {};

    for (let i = 0; i < daysCount; i++) {
      // Clone the 'today' date on each iteration to avoid modifying the original
      const nextDate = new Date(today);
      nextDate.setDate(today.getDate() + i);

      const formattedDate = moment(nextDate).format("DD-MM-YYYY");
      const dateData = res[formattedDate] || {};

      dates.push({
        day: nextDate.toLocaleDateString("en-GB", {
          month: "short",
          day: "numeric",
          year: "numeric",
        }),
        occupancy: parseFloat(dateData.gocc || 0).toFixed(2),
      });

      updatedOccupancyData[formattedDate] = dateData.hotels || [];
    }

    setdateRange(dates);
    setOccupancyData(updatedOccupancyData);
  };

  useEffect(() => {
    setmyloader(true);

    const timer = setTimeout(() => {
      setmyloader(false);
    }, 1000);

    // Cleanup the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []); // Empty dependency array to run only on mount

  const getAllData = () => {
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/reports/get-inventory-dash-report?fromDate=${moment(
        today
      ).format("DD-MM-YYYY")}&toDate=${moment(today)
        .add(6, "days")
        .format("DD-MM-YYYY")}`,
      hotelIds
    )
      .then((res) => {
        getNextDates(7, res);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getHotelDetails = (hotelId, date) => {
    const hotelsForDate =
      occupancyData[moment(date).format("DD-MM-YYYY")] || [];
    return hotelsForDate.find((hotel) => hotel.hotelId === hotelId) || {};
  };

  const [forward, setforward] = useState(false);
  const [backward, setbackward] = useState(false);
  const [oprnBulkUpdate, setoprnBulkUpdate] = useState(false);

  const openDrawer = () => {
    setoprnBulkUpdate(true);
  };

  const closeDrawer = () => {
    setoprnBulkUpdate(false);
  };

  return myloader === false ? (
    <div>
      <div className="hgello">
        {oprnBulkUpdate && activeHotels.length > 0 && (
          <GroupRightDrawer
            open={oprnBulkUpdate}
            handleAddEventSidebar={() => {
              closeDrawer();
            }}
            activeHotels={activeHotels}
          />
        )}

        <div className="d-flex">
          <h2
            style={{
              marginBottom: "1rem",
              color: "black",
              marginRight: "1rem",
            }}
          >
            Welcome to your group calendar
          </h2>

          <LocalizationProvider
            sx={{
              fontWeight: "bold !important",
              padding: "0px",
              margin: "0px",
            }}
            dateAdapter={AdapterDateFns}
          >
            <DesktopDatePicker
              inputFormat="dd MMM yyyy"
              sx={{
                fontWeight: "bold !important",
                padding: "0px",
                margin: "0px",
              }}
              value={today}
              onChange={(newValue) => {
                setToday(newValue);
              }}
              className="customizeDatePicker"
              renderInput={(params) => {
                const todayFormatted = moment(today).format("DD MMM YYYY");
                const sixDaysLater = moment(today)
                  .add(6, "days")
                  .format("DD MMM YYYY");

                // Combine the two dates with a dash
                const customLabel = `${todayFormatted} - ${sixDaysLater}`;

                params.inputProps.value = customLabel;

                return (
                  <TextField
                    sx={{
                      width: "210px",
                      fontWeight: "bold !important",
                      padding: "0px",
                      margin: "0px",
                    }}
                    className="customizeDatePicker"
                    variant="standard"
                    {...params}
                  />
                );
              }}
            />
          </LocalizationProvider>
        </div>
        <Grid
          style={{ marginBottom: "1rem" }}
          item
          container
          xs={12}
          spacing={2}
        >
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            alignSelf="center"
            justifyContent="flex-start"
            container
          >
            <Grid
              item
              xs={12}
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <IconButton
                color="primary"
                sx={{ overflow: "auto" }}
                style={{ border: "none" }}
                onClick={() => {
                  if (expanded) {
                    setbackward(true);
                    setforward(false);
                  } else {
                    setToday((prev) => {
                      const nextDate = new Date(prev);
                      nextDate.setDate(prev.getDate() - 7); // Add 7 days to the current date
                      return nextDate;
                    });
                  }
                }}
              >
                <ArrowBackIosNew />
              </IconButton>
              <FormControl style={{ width: "13rem" }}>
                {/* Label */}
                <InputLabel id="multi-select-label">Select Hotels</InputLabel>
                <Select
                  labelId="multi-select-label"
                  multiple
                  value={selectedHotels}
                  onChange={handleChanges}
                  renderValue={renderValue}
                  className="mr-4"
                  label={"Select Hotels"}
                  size="small"
                >
                  <MenuItem value="all">
                    <Checkbox checked={isAllSelected} />
                    <ListItemText primary="Select All" />
                  </MenuItem>

                  {activeHotels?.map((hotel) => (
                    <MenuItem key={hotel.hotelId} value={hotel.hotelId}>
                      <Checkbox
                        checked={selectedHotels.includes(hotel.hotelId)}
                      />
                      <ListItemText primary={hotel.hotelName} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <OutlinedInput
                onChange={(e) => setsearchTerm(e.target.value)}
                placeholder={"Search by hotel name"}
                label={"Search by hotel name"}
                // className="w-75"
                notched={false}
                type="search"
                size="small"
                style={{
                  borderRadius: "5px",
                  width: "100%",
                }}
              />
            </Grid>
          </Grid>

          <Grid item container xs={6} sm={6} md={6} lg={6} xl={6} spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              container
              justifyContent="center"
              alignSelf="center"
              style={{ marginLeft: "6rem" }}
            ></Grid>
          </Grid>
          <Grid
            spacing={2}
            item
            xs={3}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            alignSelf="center"
            justifyContent="flex-end"
            container
          >
            <Grid item container justifyContent="flex-end" xs={12}>
              {" "}
              {isAdmin && (
                <Button variant="custom-button" onClick={openDrawer}>
                  Bulk update
                </Button>
              )}
              <IconButton
                color="primary"
                onClick={() => {
                  if (expanded) {
                    setbackward(false);
                    setforward(true);
                  } else {
                    setToday((prev) => {
                      const nextDate = new Date(prev);
                      nextDate.setDate(prev.getDate() + 7); // Add 7 days to the current date
                      return nextDate;
                    });
                  }
                }}
              >
                <ArrowForwardIos />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Accordion
          style={{
            width: "100%",
            marginBottom: "3rem",
            borderRadius: "10px",
            border: "1px solid gray ",
          }}
          key={1}
          expanded={false}
        >
          <AccordionSummary
            aria-controls={`panel${1}bh-content`}
            id={`panel${1}bh-header`}
            sx={{
              paddingTop: "5px",
              paddingBottom: "0px",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{ p: 0 }}
              style={{ display: "flex", alignItems: "center" }}
            >
              {/* Properties Label */}
              <Grid
                item
                xs={12}
                sm={2}
                style={{
                  flexShrink: 0,
                  whiteSpace: "nowrap",
                  maxWidth: "15rem",
                }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: "bold", fontSize: "15px" }}
                >
                  {"Properties"}
                </Typography>
              </Grid>

              <Grid item xs={12} sm="auto">
                <div
                  style={{
                    width: "2px",
                    height: "40px",
                    backgroundColor: "#CCCCCC",
                    margin: "0 10px",
                  }}
                ></div>
              </Grid>

              {/* Date Range Mapping */}
              {dateRange.map((date, idx) => (
                <React.Fragment key={idx}>
                  <Grid
                    item
                    xs={true} // Let Grid handle equal spacing for each date range item
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      flexGrow: 1, // Ensure it grows to occupy full space
                      paddingTop: "5px",
                    }}
                  >
                    <div
                      className="w-100 d-flex"
                      style={{ justifyContent: "center" }}
                    >
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: "bold", fontSize: "15px" }}
                      >
                        {date.day}
                      </Typography>
                    </div>

                    <div
                      className="w-100 d-flex"
                      style={{ justifyContent: "center" }}
                    >
                      <Box
                        sx={{
                          bgcolor: getOccupancyColor(date.occupancy),
                          borderRadius: 1,
                          p: 0.5,
                          width: "100%", // Make the box take full width of the container
                          textAlign: "center",
                          whiteSpace: "nowrap", // Prevent text from wrapping
                        }}
                      >
                        <Typography
                          variant="body2"
                          style={{ fontWeight: "bold", fontSize: "15px" }}
                        >
                          {`${date.occupancy}%`}
                        </Typography>
                      </Box>
                    </div>
                  </Grid>

                  {idx !== 6 && (
                    <Grid item xs={12} sm="auto">
                      <div
                        style={{
                          width: "2px",
                          height: "40px",
                          backgroundColor: "#CCCCCC",
                          margin: "0 10px",
                        }}
                      ></div>
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          </AccordionSummary>
        </Accordion>
      </div>

      {activeHotels
        ?.filter((val) => {
          if (searchTerm === "") {
            return val;
          } else if (
            val.hotelName.toLowerCase().includes(searchTerm.toLowerCase())
          ) {
            return val;
          }
        })
        .filter((val) => selectedHotels.includes(val.hotelId))
        .map((item, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            style={{ marginBottom: "1rem", borderRadius: "10px" }}
          >
            <AccordionSummary
              aria-controls={`panel${1}bh-content`}
              id={`panel${1}bh-header`}
              sx={{
                paddingTop: "5px",
                paddingBottom: "0px",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <Grid
                container
                spacing={2}
                sx={{ p: 0 }}
                style={{ display: "flex", alignItems: "center" }}
              >
                {/* Properties Label */}
                <Grid
                  item
                  xs={12}
                  sm={2}
                  style={{
                    flexShrink: 0,
                    whiteSpace: "nowrap",
                    maxWidth: "15rem",
                  }}
                >
                  <Typography variant="subtitle1">
                    {
                      <Tooltip title={item.hotelName} arrow>
                        <Typography
                          variant="subtitle1"
                          style={{ width: "7rem", fontWeight: "bold" }}
                        >
                          {expanded
                            ? item.hotelName
                            : item.hotelName.length > 26
                            ? `${item.hotelName.substring(0, 26)}...`
                            : item.hotelName}
                        </Typography>
                      </Tooltip>
                    }
                  </Typography>
                </Grid>

                {expanded === false && (
                  <Grid item xs={12} sm="auto">
                    <div
                      style={{
                        width: "2px",
                        height: "40px",
                        backgroundColor: "#CCCCCC",
                        margin: "0 10px",
                      }}
                    ></div>
                  </Grid>
                )}

                {/* Date Range Mapping */}
                {expanded === false &&
                  dateRange.map((date, idx) => {
                    const { price = "N/A", occRatio = "N/A" } = getHotelDetails(
                      item.hotelId,
                      date.day
                    );
                    return (
                      <React.Fragment key={idx}>
                        <Grid
                          item
                          xs={true} // Let Grid handle equal spacing for each date range item
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            flexGrow: 1, // Ensure it grows to occupy full space
                            paddingTop: "5px",
                          }}
                        >
                          <div
                            className="w-100 d-flex"
                            style={{ justifyContent: "center" }}
                          >
                            <Typography
                              variant="subtitle2"
                              style={{ fontWeight: "bold", fontSize: "15px" }}
                            >{`${item.hotelCurrency} ${parseFloat(
                              price
                            ).toFixed(2)}`}</Typography>
                          </div>

                          <div
                            className="w-100 d-flex"
                            style={{ justifyContent: "center" }}
                          >
                            <Box
                              sx={{
                                borderRadius: 1,
                                p: 0.5,
                                width: "100%", // Make the box take full width of the container
                                textAlign: "center",
                                whiteSpace: "nowrap", // Prevent text from wrapping
                              }}
                            >
                              <Typography variant="body2">
                                <Typography
                                  variant="body2"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                  }}
                                >{`${occRatio}`}</Typography>
                              </Typography>
                            </Box>
                          </div>
                        </Grid>

                        {idx !== 6 && (
                          <Grid item xs={12} sm="auto">
                            <div
                              style={{
                                width: "2px",
                                height: "40px",
                                backgroundColor: "#CCCCCC",
                                margin: "0 10px",
                              }}
                            ></div>
                          </Grid>
                        )}
                      </React.Fragment>
                    );
                  })}
              </Grid>
            </AccordionSummary>

            <AccordionDetails>
              {/* Conditionally render Hello component only when the panel is expanded */}
              {expanded === `panel${index}` && (
                <NewGroupTimeline
                  hotelId={item.hotelId}
                  hotelCurrency={item.hotelCurrency}
                  slotType={item.slotType}
                  setToday={setToday}
                  today={today}
                  hotelCountry={item.country}
                  forward={forward}
                  backward={backward}
                  setforward={setforward}
                  setbackward={setbackward}
                  expanded={expanded}
                  occupancyData={occupancyData}
                  getAllData={getAllData}
                />
              )}
            </AccordionDetails>
          </Accordion>
        ))}
    </div>
  ) : (
    <MockLoaderTable />
  );
}

export default NewGroup;
