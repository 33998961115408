import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import ModalView from "./Views/ModalView";
import "../../../../src/index.scss";
import CustomPopper from "./Views/EventPopper";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import DimmingDots from "./Views/LoaderCustom";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import InfoIcon from "@mui/icons-material/Info";
import BalanceIcon from "@mui/icons-material/Balance";
import { MdGroups } from "react-icons/md";
import { DropdownTopbarItemToggler } from "../../../_metronic/_partials/dropdowns";
import { Modal } from "react-bootstrap";
import GroupsIcon from "@mui/icons-material/Groups";
import { connect } from "react-redux";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import BookingModal from "./Views/BookingModal";
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Drawer,
  FormControl,
  Grid,
  Grow,
  IconButton,
  InputLabel,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Select,
  Slide,
  Tooltip,
  Typography,
  useMediaQuery,
  Badge,
  Switch,
  Divider,
  TextField,
  CircularProgress,
  Stack,
  Checkbox,
} from "@mui/material";
import { makeStyles, useTheme, withStyles } from "@mui/styles";
import { getBookingData, getReservationInfoDjango } from "./api/ReservationApi";
import {
  channelLogoMap,
  dateFormat,
  getSlotType,
  multiOptions,
  navigationParamsForCalendar,
  views,
} from "./constants/Calendar-constants";
import { BookingDataModel } from "./models/BookingDataModel";
import moment from "moment";
import EditModalView from "./Views/EditModalView";
import ExtendModalView from "./Views/ExtendModalView";
import DragModalView from "./Views/DragModalView";
import {
  ArrowBackIosNew,
  ArrowForwardIos,
  FiberManualRecord,
  Lock,
  MoreVert,
} from "@mui/icons-material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { shallowEqual, useSelector } from "react-redux";
import { isNull, isUndefined } from "lodash";
import { getAllNonHiddenRoomTypes } from "../InventoryCalendar/api/InventoryApi";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import MultipleBookingView from "./Views/MultipleBookingView";
import "../../../index.scss";
import "@fullcalendar/resource-timeline/main.min.css";
import UnassignedView from "./Views/UnassignedView";
import Folios from "../Folios/Folios";
import UnblockView from "./Views/UnblockView";
import * as AiIcons from "react-icons/ai";
import { AMPLITUDE_KEY, ENV } from "./constants/AmplitudeConstants";
import amplitude from "amplitude-js";
import { getAllRoomTypess } from "../RevenueManagement/RatePlans/apis/RatePlanApis";
import { Dropdown } from "react-bootstrap";
import {
  djangoHandleDataMutationRequest,
  djangoHandleDataRequests,
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../api";
import { toast } from "material-react-toastify";
import NewEnquiry from "./Views/NewEnquiry";
import BlockRoom from "./Views/BlockRoom";
import Walkin from "./Views/Walkin";
import { Link } from "react-router-dom";
import ClickAwayListenerHelper from "../../Utils/ClickAwayListenerHelper";
import {
  Document,
  Page,
  PDFDownloadLink,
  StyleSheet,
} from "@react-pdf/renderer";
import PrintEmptyResCard from "../Folios/PrintEmptyResCard";
import PrintResCard from "../Folios/PrintResCard";
import Freeze from "../../Utils/Freeze";
import { getAllImagesAndUrl } from "../BookingDashboard/apis/BrandingModalApi";
import { useHistory } from "react-router-dom";
import i18n from "../../modules/Auth/pages/i18n";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    padding: 0,
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
    width: "95%",
  },
  drawers: {
    flexShrink: 0,
    width: "55%",
  },
  drawerPaper: {
    width: "95%",
  },
  drawerPapers: {
    width: "55%",
  },
}));

const DemoApp = ({ hotelType, selectedLanguage }) => {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);
  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );

  console.log("hotel", hotel);
  const history = useHistory();
  const [subUser, setsubUser] = useState(hotel?.accessControl?.ADMINRIGHTS);
  const [hotelemail, sethotelemail] = useState(
    hotel?.accessControl?.hotel?.hotel_email
  );

  const hotelSlot = getSlotType(hotel.slotType);
  const Noonslot = { day: 0, hour: 8 };
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const hotelId = hotel.hotelId;
  const calendarRef = useRef(null);
  const navigationParams = Object.assign(navigationParamsForCalendar);
  navigationParams.hotelId = hotelId;
  let [actionType, setActionType] = useState("");
  let [showModal, setShowModal] = useState({});
  let [show, setShow] = useState(false);
  let [initialData, setInitialData] = useState(new BookingDataModel({}));
  let [calendarApi, setCalendarApi] = useState(null);
  let [loading, setLoading] = useState(false);
  const [showRoomView, setshowRoomView] = useState(false);
  // let [snackbar, setSnackbar] = useState(false);
  const [rooms, setRooms] = useState([
    { text: "Available Rooms", id: "availableRooms" },
    { text: "Blocked Rooms", id: "blockedRooms" },
    { text: "Dirty Rooms", id: "dirtyRooms" },
    { text: "Clean Rooms", id: "cleanRooms" },
    { text: "Occupied Rooms", id: "bookedRooms" },
    { text: "All", id: "All" },
    { text: "Unselect", id: "Unselect" },
  ]);
  const [selectedRoom, setSelectedRoom] = useState([]);
  const [today, setToday] = useState(new Date());
  const [roomNumbers, setRoomNumbers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(Boolean(anchorEl));
  const [multiOption, setMultiOption] = useState("");
  const onOpenEditCustomerModal = () => setOpenEditCustomerModal(true);
  const onCloseEditCustomerModal = () => setOpenEditCustomerModal(false);
  const [openEditCustomerModal, setOpenEditCustomerModal] = useState(false);
  const [openServiceModal, setOpenServiceModal] = useState(false);
  const onOpenServiceModal = () => setOpenServiceModal(true);
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const windowWidth = window.screen.innerWidth;
  const [state, setState] = useState({ right: false });
  const [showUnassigned, setShowUnassigned] = useState(false);
  const [unassignedDate, setUnassignedDate] = useState(new Date());
  const [originalAllRoomInfo, setOriginalAllRoomInfo] = useState([]);
  const [showRoomName, setshowRoomName] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [dirtyRoomId, setdirtyRoomId] = useState("");
  const [openBookingModal, setopenBookingModal] = useState(false);
  const [bookingData, setbookingData] = useState({});
  const [reservationData, setreservationData] = useState({});
  const envType = process.env.REACT_APP_ENV;
  const [whiteLabelled, setWhiteLabelled] = useState("ENABLED");
  const [dashboardView, setDashboardView] = useState(
    sessionStorage.getItem("fullView") === "false" ? "roomView" : "fullView"
  );

  useEffect(() => {
    if (hotelId !== "" && hotelId !== null && hotelId !== undefined) {
      if (
        sessionStorage.getItem("fullView") === "false" ||
        sessionStorage.getItem("fullView") === false
      ) {
        history.push(`/roomcalendar?hotelId=${hotelId}`);
      } else {
        history.push(`/calendar?hotelId=${hotelId}`);
      }
    }
  }, [sessionStorage.getItem("fullView"), dashboardView, hotelId]);

  // Magic link modal
  const [openMagicLinkModal, setOpenMagicLinkModal] = useState(false);
  const handleOpenMagicLinkModal = () => {
    setOpenMagicLinkModal(true);
  };
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(async () => {
    if (Object.entries(bookingData).length > 0) {
      const data = await getReservationInfoDjango(bookingData.reservationId);
      setreservationData(data);
      setMagicLinkMail(data.user_email);
      setMagicLinkPhone(data.user_contact);
      setUpiAmt(data.balance_due);
      setAmount(data.balance_due);
      setOfflineCardAmt(data.balance_due);
      setBankTransferModeAmt(data.balance_due);
      setOtherModeAmt(data.balance_due);
      setOfflineCheckAmt(data.balance_due);
      setPaymentLinkAmt(data.balance_due);
    }
  }, [bookingData]);

  const handleCloseMagicLinkModal = () => {
    setOpenMagicLinkModal(false);
  };
  const userBrandingSatus = async () => {
    try {
      const data = await getAllImagesAndUrl();
      if (data?.status === null || data?.status === undefined) {
        setWhiteLabelled("DISABLED");
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    userBrandingSatus();
  }, []);

  const whitlableArrMap = [
    "STAYFLEXI_OD",
    "STAYFLEXI_OM",
    "STAYFLEXI MOBILE",
    "STAYFLEXI_GHA",
    "STAYFLEXI_BE",
    "STAYFLEXI_MOBILE",
  ];

  // handling booking confirmation email loading
  const [magicLinkLoading, setMagicLinkLoading] = useState(false);

  // pre populating magic link modal fields
  const [magicLinkMail, setMagicLinkMail] = useState("");
  const [magicLinkPhone, setMagicLinkPhone] = useState("");
  // send magic link
  const validateEmail = (email) => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(String(email).toLowerCase());
  };

  function handleSendMagicLink() {
    if (!validateEmail(magicLinkMail)) {
      toast.error(`${t("Please enter valid email")}`);
      setMagicLinkLoading(false);
      return;
    }
    if (magicLinkMail.includes("example.com")) {
      toast.error(`${t("Please enter valid email")}`);
      setMagicLinkLoading(false);
      return;
    }
    if (magicLinkMail == "" || magicLinkPhone == "") {
      toast.error(
        `${t("Empty email or phone, please enter valid email and phone.")}`
      );
      setMagicLinkLoading(false);
      return;
    }
    setMagicLinkLoading(true);
    djangoHandleDataMutationRequest(
      "POST",
      "invoice/sendSelfServiceLinkCust/",
      {
        bookingId: bookingData.booking_id,
        email: magicLinkMail,
        phoneNumber: magicLinkPhone,
      }
    )
      .then((res) => toast.success(`${t("Magic link sent successfully.")}`))
      .then((res) => setMagicLinkLoading(false))
      .then((res) => handleCloseMagicLinkModal())
      .then((res) => setMagicLinkMail(reservationData.user_email))
      .then((res) => setMagicLinkPhone(reservationData.user_contact))
      .catch((err) => toast.error(err))
      .catch((err) => setMagicLinkLoading(false));
  }

  const openTheBookingModal = (resp) => {
    setbookingData(resp);
    setopenBookingModal(true);
  };
  const closeTheBookingModal = () => setopenBookingModal(false);

  //Amplitude check on create booking page
  const amplitudeEventProperties = {
    hotel_id: hotel?.hotelId,
    environment: ENV,
    email: hotel?.accessControl?.email,
    role: hotel?.accessControl?.ADMINRIGHTS,
  };

  /* useEffect(() => {
    amplitude.getInstance().init(AMPLITUDE_KEY, hotel?.hotelId, {
      saveEvents: true,
      includeUtm: true,
      includeReferrer: true,
    });
    amplitude
      .getInstance()
      .identify(
        new amplitude.Identify()
          .set("hotel_id", hotel?.hotelId)
          .set("email", hotel?.accessControl?.email)
          .set("role", hotel?.accessControl?.ADMINRIGHTS)
      );
    amplitude
      .getInstance()
      .logEvent("CREATE BOOKING PAGE LOADED", amplitudeEventProperties);
  }, []); */

  const classes = useStyles();
  const changeDateTime = (data) => {
    showModal.event.setDates(data.start, data.end);
    handleAddEventSidebar();
    fetchBookingDataWithoutLoad();
  };

  const moveToNewRoom = (id) => {
    showModal.event.setResources([id]);
    handleAddEventSidebar();
    fetchBookingDataWithoutLoad();
  };

  const getStartEndDates = () => {
    let title = calendarApi.view.type;
    let currentDate = calendarApi.getDate();
    let endDate = new Date(currentDate);
    endDate.setDate(currentDate.getDate() + (views[title] - 1));
    return { start: currentDate, end: endDate };
  };

  const lockEvent = (lockType) => {
    showModal.event.setExtendedProp("locked_status", lockType);
    handleAddEventSidebar();
  };

  const removeEvent = () => {
    showModal.event.remove();
    if (calendarApi !== null) {
      calendarApi.refetchEvents();
    }
    handleAddEventSidebar();
    fetchBookingDataWithoutLoad();
  };

  // const toggleDrawer = (anchor, open, id) => {
  //   setSelectedBookingId(id);
  //   // const data = await viewFolio(id);

  //   setState({ ...state, [anchor]: open });
  // };

  // const folioDrawer = (anchor) => (
  //   <Box
  //     sx={{
  //       width:
  //         anchor === "top" || anchor === "bottom" ? "auto" : windowWidth - 100,
  //     }}
  //     role="presentation"
  //     // onClick={toggleDrawer(anchor, false)}
  //     // onKeyDown={toggleDrawer(anchor, false)}
  //   >
  //     <div
  //       style={{
  //         position: "relative",
  //         width: "100%",
  //         padding: "25px",
  //       }}
  //     >
  //       <div
  //         onClick={() => toggleDrawer("right", false)}
  //         style={{
  //           position: "relative",
  //           cursor: "pointer",
  //           marginRight: "25px",
  //           width: "100%",
  //           display: "flex",
  //           justifyContent: "flex-end",
  //           backgroundColor: "transparent",
  //         }}
  //       >
  //         <AiIcons.AiOutlineClose size="25" color="black" />
  //       </div>

  //       {selectedBookingId && (
  //         <Folios
  //           onOpenCustModal={onOpenEditCustomerModal}
  //           onOpenAddServiceModal={onOpenServiceModal}
  //           booking_id={selectedBookingId}
  //           fetchBookingData={fetchBookingData}
  //           isGrid={true}
  //           // onCloseAdvFolio={toggleDrawer("right", false)}
  //         />
  //       )}
  //     </div>
  //   </Box>
  // );

  // Creating a new event when selecting in a empty space
  const handleDateSelect = (data) => {
    if (data.event.extendedProps.status === "COMBO") return;
    if (data.event.extendedProps.status === "BLOCKED")
      setActionType("edit-blocked");
    else setActionType("edit");
    setScrollPosition(window.pageYOffset);
    setShowModal(data);
    setShow(true);
  };

  const handleCleanRoom = () => {
    axios
      .post(
        `${process.env.REACT_APP_BG_API_URL}core/api/v1/hk/bulk-mark-rooms-clean?${hotelId}`,
        {
          roomIds: [`${dirtyRoomId}`],
          staffId: "",
        }
      )
      .then((res) => {
        toast.success(`${t("Successfully cleaned room")}`);
        fetchBookingDataWithoutLoad();
        handleClose();
      })
      .catch((err) => {
        toast.error(`${t("Something went wrong")}`);
        fetchBookingDataWithoutLoad();
        handleClose();
      });
  };

  // When event position changes for Drag and drop and extension
  const handleDragStop = (data) => {
    if (
      data.event.getResources()[0].id !== data.oldEvent.getResources()[0].id &&
      data.oldEvent.startStr !== data.event.startStr &&
      data.oldEvent.endStr !== data.event.endStr
    ) {
      data.revert();
      return;
    }
    setShowModal(data);
    setShow(true);
  };

  // Closing the modal for creating new event
  const handleAddEventSidebar = () => {
    setShow(!show);
    setShowModal(null);

    setTimeout(() => {
      window.scrollTo(0, scrollPosition);
    }, 700);
  };

  // Reverting the changes when drag and drop is cancelled
  const modify = () => {
    showModal.revert();
    handleAddEventSidebar();
  };

  // Opening a modal for new event in the empty boxes
  const addNew = (data) => {
    setActionType("add");
    setScrollPosition(window.pageYOffset);
    setShowModal(data);
    setShow(true);
  };

  // Adding the new event to the calendar
  const setNewData = (obj, val) => {
    if (!val) {
      calendarApi.addEvent(obj);
      if (calendarApi !== null) {
        calendarApi.refetchEvents();
      }
    }

    // if (val === "success") {
    //   setSnackbar(true);
    // }
    handleAddEventSidebar();
  };

  const checkForEmptyCalendar = () => {
    if (isNull(calendarApi)) {
      return false;
    }
    if (isUndefined(calendarApi.view)) {
      return false;
    }

    return true;
  };

  const fetchBookingDataWithoutLoad = async () => {
    const params = getParamsForView("any");
    initialData.roomsData = [];
    initialData.bookingData = [];

    if (params?.roomTypes?.includes("blockedRooms")) {
      params.blockedRooms = true;
    } else if (params?.roomTypes?.includes("availableRooms")) {
      params.availableRooms = true;
    } else if (params?.roomTypes?.includes("dirtyRooms")) {
      params.dirtyRooms = true;
    } else if (params?.roomTypes?.includes("cleanRooms")) {
      params.cleanRooms = true;
    } else if (params?.roomTypes?.includes("bookedRooms")) {
      params.bookedRooms = true;
    }
    if (params?.roomTypes !== undefined && params?.roomTypes !== null) {
      params.roomTypes = params?.roomTypes.filter(
        (item) =>
          item !== "blockedRooms" &&
          item !== "availableRooms" &&
          item !== "dirtyRooms" &&
          item !== "cleanRooms" &&
          item !== "bookedRooms"
      );
    } else {
      params.roomTypes = null;
    }
    getUnassignedCount(params);
    const data = await getBookingData(params, history);
    const resp = new BookingDataModel(
      data,
      params.viewType,
      hotel.slotType === "NOON" ? "THREE_SLOT" : hotel.slotType
    );
    calendarApi.removeAllEvents();
    setRoomNumbers(resp.roomsData);
    setInitialData(resp);
  };

  // useEffect(() => {
  //   fetchBookingDataWithoutLoad()
  // }, [])

  //Fix
  const [unassingedCount, setunassingedCount] = useState(0);
  const getUnassignedCount = (params) => {
    if (params) {
      handleSpringDataMutationRequest(
        "POST",
        `core/api/v1/reservation/getUnassignCount`,
        {
          hotelId: hotelId,
          numOfDays:
            sessionStorage.getItem("types") === "month"
              ? 30
              : sessionStorage.getItem("types") === "day"
              ? 1
              : 7,
          roomIdsSort: true,
          roomTypes: params === undefined ? null : params.roomTypes,
          startDate:
            params === undefined
              ? hotel?.hotelCurrency === "USD"
                ? moment(new Date(new Date().getTime() - 86400000))
                    .set({ hour: 0, minute: 0, second: 0 })
                    .format(dateFormat)
                : moment(new Date())
                    .set({ hour: 0, minute: 0, second: 0 })
                    .format(dateFormat)
              : params.startDate,
          viewType:
            params === undefined ? "resourceTimelineWeek" : params?.viewType,
        }
      )
        .then((res) => setunassingedCount(Number(res)))
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    getUnassignedCount();
  }, [sessionStorage.getItem("types")]);

  const reloadData = () => {
    let tempData = getParamsForView("month");
    fetchBookingDataWithParams(tempData, "month");
  };

  const fetchBookingDataWithParams = async (params, type) => {
    setLoading(true);
    calendarApi.removeAllEvents();
    initialData.roomsData = [];
    initialData.bookingData = [];
    getUnassignedCount(params);

    if (params?.roomTypes?.includes("blockedRooms")) {
      params.blockedRooms = true;
    } else if (params?.roomTypes?.includes("availableRooms")) {
      params.availableRooms = true;
    } else if (params?.roomTypes?.includes("dirtyRooms")) {
      params.dirtyRooms = true;
    } else if (params?.roomTypes?.includes("cleanRooms")) {
      params.cleanRooms = true;
    } else if (params?.roomTypes?.includes("bookedRooms")) {
      params.bookedRooms = true;
    }

    if (params?.roomTypes !== null && params?.roomTypes !== undefined) {
      params.roomTypes =
        params?.roomTypes.length > 0 &&
        params?.roomTypes.filter(
          (item) =>
            item !== "blockedRooms" &&
            item !== "availableRooms" &&
            item !== "dirtyRooms" &&
            item !== "cleanRooms" &&
            item !== "bookedRooms"
        );
    } else {
      params.roomTypes = null;
    }
    if (
      params?.roomTypes !== null &&
      params?.roomTypes !== undefined &&
      params?.roomTypes === false
    ) {
      params.roomTypes = null;
    }

    const data = await getBookingData(params, history);
    let resp;
    if (type === "month" || sessionStorage.getItem("types") === "month") {
      resp = new BookingDataModel(data, params.viewType, hotel.slotType);
    } else {
      resp = new BookingDataModel(
        data,
        params.viewType,
        hotel.slotType === "NOON" ? "THREE_SLOT" : hotel.slotType
      );
    }
    setRoomNumbers(resp.roomsData);
    setInitialData(resp);
    setLoading(false);
  };

  const fetchBookingData = async () => {
    try {
      setLoading(true);
      let temp = Object.assign(navigationParams);
      temp.startDate =
        hotel?.hotelCurrency === "USD"
          ? moment(new Date(new Date().getTime() - 86400000))
              .set({ hour: 0, minute: 0, second: 0 })
              .format(dateFormat)
          : moment(new Date())
              .set({ hour: 0, minute: 0, second: 0 })
              .format(dateFormat);

      if (temp?.roomTypes?.includes("blockedRooms")) {
        temp.blockedRooms = true;
      } else if (temp?.roomTypes?.includes("availableRooms")) {
        temp.availableRooms = true;
      } else if (temp?.roomTypes?.includes("dirtyRooms")) {
        temp.dirtyRooms = true;
      } else if (temp?.roomTypes?.includes("cleanRooms")) {
        temp.cleanRooms = true;
      } else if (temp?.roomTypes?.includes("bookedRooms")) {
        temp.bookedRooms = true;
      }

      if (temp?.roomTypes !== null && temp?.roomTypes !== undefined) {
        temp.roomTypes =
          temp?.roomTypes.length > 0 &&
          temp?.roomTypes.filter(
            (item) =>
              item !== "blockedRooms" &&
              item !== "availableRooms" &&
              item !== "dirtyRooms" &&
              item !== "cleanRooms" &&
              item !== "bookedRooms"
          );
      } else {
        temp.roomTypes = null;
      }

      const data = await getBookingData(temp, history);
      const resp = new BookingDataModel(
        data,
        "resourceTimelineWeek",
        hotel.slotType === "NOON" ? "THREE_SLOT" : hotel.slotType
      );
      setRoomNumbers(resp.roomsData);
      setOriginalAllRoomInfo(data.allRoomReservations.singleRoomReservations);

      setInitialData(resp);
      setLoading(false);
    } catch (er) {
      console.log(er);
    }
  };

  const getParamsForView = (val) => {
    let title = calendarApi?.view?.type;

    let currentDate = calendarApi?.getDate();

    let tempParams = Object.assign(navigationParams);
    if (views[title] === 30) {
      if (val === "subtract") {
        currentDate.setDate(currentDate.getDate() - views[title]);
      }
      if (val === "add") {
        currentDate.setDate(currentDate.getDate() + views[title]);
      }
    } else {
      if (val === "subtract") {
        currentDate.setDate(currentDate.getDate() - views[title]);
      }
      if (val === "add") {
        currentDate.setDate(currentDate.getDate() + views[title]);
      }
    }

    tempParams.viewType = title;
    tempParams.numOfDays = views[title];
    tempParams.availableRooms = selectedRoom?.includes("availableRooms");
    tempParams.blockedRooms = selectedRoom?.includes("blockedRooms");
    tempParams.dirtyRooms = selectedRoom?.includes("dirtyRooms");
    tempParams.cleanRooms = selectedRoom?.includes("cleanRooms");
    tempParams.bookedRooms = selectedRoom?.includes("bookedRooms");
    tempParams.startDate = moment(currentDate)
      .set({ hour: 0, minute: 0, second: 0 })
      .format(dateFormat);
    const temporary = [];
    if (selectedRoom.some((e) => e.id === "All") || selectedRoom.length === 0) {
      tempParams.roomTypes = rooms.forEach((e) => {
        if (e.id !== "All" && e.id !== "Unselect") temporary.push(e.id);
      });
    } else {
      selectedRoom.forEach((room) => {
        temporary.push(room.id);
      });
      tempParams.roomTypes = temporary;
    }
    tempParams.clusterRooms = sessionStorage.getItem("showRoomName")
      ? sessionStorage.getItem("showRoomName")
      : false;
    return tempParams;
  };

  const getRoomsData = async () => {
    const resp = await getAllRoomTypess();
    const temp_rooms = [];

    for (const [key, value] of Object.entries(resp)) {
      rooms.push({ text: value, id: key });
    }
    setRooms(rooms);
    setshowRoomView(Object.entries(resp).length > 1);
    setSelectedRoom(rooms.filter((e) => e.text === "All"));
  };
  // To detect the changes in the calendar and re-rendering
  useEffect(() => {
    if (calendarApi === null) {
      getRoomsData();
      fetchBookingData();
      setCalendarApi(calendarRef.current.getApi());
    }
  }, [calendarApi]);

  const getSource = (source) => {
    if (source !== undefined) {
      if (channelLogoMap[source] === undefined) return "";
      return channelLogoMap[source];
    }
  };

  useEffect(() => {
    if (
      calendarApi !== null &&
      calendarApi !== undefined &&
      calendarApi !== ""
    ) {
      if (
        sessionStorage.getItem("types") !== null &&
        sessionStorage.getItem("types") !== undefined &&
        sessionStorage.getItem("types") !== ""
      ) {
        if (sessionStorage.getItem("types") === "day") {
          calendarApi.changeView("resourceTimelineDay");
          let tempData = getParamsForView("day");

          fetchBookingDataWithParams(tempData, "day");
        } else if (sessionStorage.getItem("types") === "week") {
          calendarApi.changeView("resourceTimelineWeek");
          let tempData = getParamsForView("week");
          fetchBookingDataWithParams(tempData, "week");
        } else if (sessionStorage.getItem("types") === "month") {
          calendarApi.changeView("resourceTimelineMonthCustom");
          let tempData = getParamsForView("month");
          fetchBookingDataWithParams(tempData, "month");
        }
      } else {
        sessionStorage.setItem(
          "types",
          hotel.slotType === "PURE_HOURLY" ? "day" : "week"
        );
      }
    }
    setScrollPosition(window.pageYOffset);
  }, [sessionStorage.getItem("types"), calendarApi]);

  // const [checked, setChecked] = useState(false);

  const handleChangeName = (event) => {
    // setChecked(event.target.checked);
    if (event.target.checked) {
      let tempData = getParamsForView(sessionStorage.getItem("types"));
      tempData.clusterRooms = true;
      fetchBookingDataWithParams(tempData, sessionStorage.getItem("types"));
    } else {
      let tempData = getParamsForView(sessionStorage.getItem("types"));
      tempData.clusterRooms = false;
      fetchBookingDataWithParams(tempData, sessionStorage.getItem("types"));
    }

    setshowRoomName(event.target.checked);
    sessionStorage.setItem("showRoomName", event.target.checked);
  };

  useEffect(() => {
    if (
      sessionStorage.getItem("showRoomName") === undefined ||
      sessionStorage.getItem("showRoomName") === null ||
      sessionStorage.getItem("showRoomName") === "" ||
      sessionStorage.getItem("types") === undefined ||
      sessionStorage.getItem("types") === null ||
      sessionStorage.getItem("types") === ""
    ) {
      setshowRoomName(false);
    } else {
      const data =
        sessionStorage.getItem("showRoomName") === "true" ? true : false;

      if (
        calendarApi !== undefined &&
        calendarApi !== null &&
        calendarApi !== ""
      ) {
        if (data) {
          let tempData = getParamsForView(sessionStorage.getItem("types"));
          tempData.clusterRooms = true;
          fetchBookingDataWithParams(tempData, sessionStorage.getItem("types"));
        } else {
          let tempData = getParamsForView(sessionStorage.getItem("types"));
          tempData.clusterRooms = false;
          fetchBookingDataWithParams(tempData, sessionStorage.getItem("types"));
        }
      }

      setshowRoomName(data);
    }
    setScrollPosition(window.pageYOffset);
  }, [
    sessionStorage.getItem("showRoomName"),
    sessionStorage.getItem("types"),
    calendarApi,
  ]);

  // Payment Flows
  const loggedUserEmail = localStorage.getItem("email");
  const [offlineCardPayLoading, setOfflineCardPayLoading] = useState(false);

  // accepting cash payment modal
  const [openCashPayment, setOpenCashPayment] = useState(false);
  const handleOpenCashPayment = () => {
    if (
      hotelType === "ISHA" &&
      Number(reservationData.total_amount_with_services).toFixed(2) > 100000
    ) {
      toast.error(
        `${t("Cannot take cash transaction of more than 100000 in cash")}`
      );
    } else {
      if (hotelType === "ISHA") {
        toast.error(
          `${t(
            "Please ensure to preview the E-Receipt before accepting the payment"
          )}`
        );
      }
      setOpenCashPayment(true);
    }
  };

  // handling the format in which data is being sent
  const handleSentDateFormat = (recordDate) => {
    const currentDate = new Date();
    if (
      moment(currentDate).format("YYYY-MM-DD") ==
      moment(recordDate).format("YYYY-MM-DD")
    ) {
      return moment(recordDate).format("YYYY-MM-DD HH:mm:ss");
    }
    return moment(recordDate).format("YYYY-MM-DD") + " 00:00:00";
  };

  // cash payment loading
  const [cashPayLoading, setCashPayLoading] = useState(false);
  const [amount, setAmount] = useState(
    Number(reservationData.balance_due).toFixed(2)
  );
  // cheque pay loading
  const [offlineCheckPayLoading, setOfflineCheckPayLoading] = useState(false);
  const [offlineCardAmt, setOfflineCardAmt] = useState(
    Number(reservationData.balance_due).toFixed(2)
  );
  const [offlineCheckDate, setOfflineCheckDate] = useState(new Date());
  const [offlineCashDate, setOfflineCashDate] = useState(new Date());
  const [chequeDesc, setChequeDesc] = useState();
  const [serviceAmountDesc, setServiceAmountDesc] = useState();
  const [offlineCardDesc, setOfflineCardDesc] = useState();
  const handleCloseCashPayment = () => setOpenCashPayment(false);
  const currency = hotel.accessControl.hotel.hotel_currency;
  const handleCloseOfflineCardPay = () => {
    setOpenOfflineCardPay(false);
  };

  const handleCashPayment = () => {
    setCashPayLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordCashPayment/`,
        {
          hotelId: hotel.hotelId,
          bookingID: reservationData.booking_id,
          reservation_id: "",
          amount: amount,
          offline_card_date: handleSentDateFormat(offlineCashDate),
          service_amount_desc: serviceAmountDesc,
          logged_user_email: loggedUserEmail,
        }
      )
      .then((res) =>
        toast.success(`${t("Cash payment of")} ${amount} ${t("recorded")}`)
      )
      .then((res) => setCashPayLoading(false))
      .then((res) => setAmount(""))
      .then((res) => setServiceAmountDesc())
      .then((res) => handleCloseCashPayment())
      .catch((error) => setCashPayLoading(false))
      .catch((error) => handleCloseCashPayment())
      .catch((error) => toast.error(error));
  };

  // UPI payment modal loading
  const [openUpi, setOpenUpi] = useState(false);
  const handleOpenUpiPay = () => setOpenUpi(true);
  const handleCloseUpiPay = () => setOpenUpi(false);
  const [upiPayLoading, setUpiPayLoading] = useState(false);

  const [upiDate, setUpiDate] = useState(new Date());

  const [upiAmt, setUpiAmt] = useState(reservationData?.balance_due);
  const [upiDesc, setUpiDesc] = useState("");

  const handleUpiPayment = () => {
    setUpiPayLoading(true);
    axios
      .post(`${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordUPIPayment/`, {
        hotelId: hotel?.hotelId,
        bookingID: reservationData?.booking_id,
        reservation_id: "",
        amount: Number(upiAmt),
        offline_upi_date: handleSentDateFormat(upiDate),
        service_amount_desc: upiDesc,
        logged_user_email: loggedUserEmail,
      })
      // .then((res) => updateSummaryDataFromCache())
      .then((res) =>
        toast.success(
          `${t("UPI payment of")} ${upiAmt} ${t("recorded for")} ${
            reservationData?.booking_id
          }!`
        )
      )

      .then((res) => setUpiPayLoading(false))
      .then((res) => setUpiAmt(""))
      .then((res) => setUpiDate(new Date()))
      .then((res) => setUpiDesc(""))
      .then((res) => handleCloseUpiPay())
      .catch((err) => toast.error(err))
      .then((err) => setUpiPayLoading(false))
      .then((err) => handleCloseUpiPay());
  };

  // Other payment mode modal loading
  const [openOtherModePay, setOpenOtherModePay] = useState(false);
  const handleOpenOtherModePay = () => setOpenOtherModePay(true);
  const handleCloseOtherModePay = () => setOpenOtherModePay(false);
  const [otherModePayLoading, setOtherModePayLoading] = useState(false);

  // Bank transfer payment mode modal
  const [openBankTransferModePay, setOpenBankTransferModePay] = useState(false);
  const handleOpenBankTransferModePay = () => setOpenBankTransferModePay(true);
  const handleCloseBankTransferModePay = () =>
    setOpenBankTransferModePay(false);

  const [bankTransferModePayLoading, setBankTransferModePayLoading] =
    useState(false);

  const [bankTransferModeDate, setBankTransferModeDate] = useState(new Date());

  const [bankTransferModeAmt, setBankTransferModeAmt] = useState(
    reservationData?.balance_due
  );
  const [bankTransferModeDesc, setBankTransferModeDesc] = useState("");

  const [otherModeDate, setOtherModeDate] = useState(new Date());

  const [otherModeAmt, setOtherModeAmt] = useState(
    reservationData?.balance_due
  );
  const [otherModeDesc, setOtherModeDesc] = useState("");

  const handleBankTransferPayment = () => {
    setBankTransferModePayLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordBankTransferPayment/`,
        {
          hotelId: hotel?.hotelId,
          bookingID: reservationData?.booking_id,
          reservation_id: "",
          amount: bankTransferModeAmt,
          bank_transfer_date: handleSentDateFormat(bankTransferModeDate),
          service_amount_desc: bankTransferModeDesc,
          logged_user_email: loggedUserEmail,
        }
      )
      .then((res) =>
        toast.success(
          `${t("Bank transfer payment of")} ${bankTransferModeAmt} ${t(
            "recorded for"
          )} ${reservationData?.booking_id}!`
        )
      )
      .catch((err) => toast.error(err))
      .finally(() => setBankTransferModeAmt(""))
      .finally(() => setBankTransferModeDate(new Date()))
      .finally(() => setBankTransferModeDesc(""))
      .finally(() => handleCloseBankTransferModePay())
      .finally(() => setBankTransferModePayLoading(false));
  };

  const handleOtherModePayment = () => {
    setOtherModePayLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordOtherPayment/`,
        {
          hotelId: hotel?.hotelId,
          bookingID: reservationData?.booking_id,
          reservation_id: "",
          amount: otherModeAmt,
          other_payment_date: handleSentDateFormat(otherModeDate),
          service_amount_desc: otherModeDesc,
          logged_user_email: loggedUserEmail,
        }
      )
      .then((res) =>
        toast.success(
          `${t("Other payment of")} ${otherModeAmt} ${t("recorded for")} ${
            reservationData?.booking_id
          }!`
        )
      )

      .then((res) => setOtherModePayLoading(false))
      .then((res) => setOtherModeAmt(""))
      .then((res) => setOtherModeDate(new Date()))
      .then((res) => setOtherModeDesc(""))
      .then((res) => handleCloseOtherModePay())
      .catch((err) => setOtherModePayLoading(false))
      .catch((err) => handleCloseOtherModePay())
      .catch((err) => toast.error(err));
  };

  // paymentLink modal

  const [openSendPaymentLink, setOpenSendPaymentLink] = useState(false);
  const handleOpenSendPaymentLink = () => setOpenSendPaymentLink(true);
  const handleCloseSendPaymentLink = () => setOpenSendPaymentLink(false);

  // cash deposit modal

  const [openCashDeposit, setOpenCashDeposit] = useState(false);
  const handleOpenCashDeposit = () => setOpenCashDeposit(true);
  const handleCloseCashDeposit = () => setOpenCashDeposit(false);
  const [offlineCheckAmt, setOfflineCheckAmt] = useState(
    reservationData?.balance_due
  );

  // handling cash deposit date format
  const handleOpenCashDepositDate = (cashDepositDate) => {
    const currentDate = new Date();
    if (
      moment(currentDate).format("YYYY-MM-DD") ==
      moment(cashDepositDate).format("YYYY-MM-DD")
    ) {
      return moment(cashDepositDate).format("MMM DD YYYY HH:mm:ss");
    }
    return moment(cashDepositDate).format("MMM DD YYYY") + " 00:00:00";
  };
  // deposit loading
  const [depositLoading, setDepositLoading] = useState(false);
  // handling the cash deposit

  const handleDeposit = () => {
    setDepositLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/addServiceToInvoice/`,
        {
          hotelId: hotel?.hotelId,
          booking_id: reservationData?.booking_id,
          reservation_id: "",
          service_item: {
            service_date: handleOpenCashDepositDate(depositDate),
            service_amount: depositAmt,
            service_desc: depositName,
            service_amount_type: "DEPOSIT",
          },
          logged_user_email: loggedUserEmail,
        }
      )
      .then((res) => toast.success(`${t("Deposit recorded successfully.")}`))

      .then((res) => setDepositLoading(false))
      .then((res) => setDepositAmt(""))
      .then((res) => setDepositDate(new Date()))
      .then((res) => setDepositName())
      .then((res) => handleCloseCashDeposit())
      .catch((error) => setDepositLoading(false))
      .catch((error) => handleCloseCashDeposit())
      .catch((error) => toast.error(error));
  };

  // fields for for cash deposit

  const [depositAmt, setDepositAmt] = useState(0);
  const [depositDate, setDepositDate] = useState(new Date());
  const [depositName, setDepositName] = useState();
  const [offlineCardDate, setOfflineCardDate] = useState(new Date());
  const handleCloseOfflineCheckPay = () => setOpenOfflineCheckPay(false);

  const [openOfflineCardPay, setOpenOfflineCardPay] = useState(false);
  const handleOpenOfflineCardPay = () => {
    if (hotelType === "ISHA") {
      toast.error(
        `${t(
          "Please ensure to preview the E-Receipt before accepting the payment"
        )}`
      );
    }
    setOpenOfflineCardPay(true);
  };

  const handleOfflineCardPayment = () => {
    const payload = {
      hotelId: hotel?.hotelId,
      bookingID: reservationData.booking_id,
      reservation_id: "",
      amount: offlineCardAmt,
      offline_card_date: handleSentDateFormat(offlineCardDate),
      service_amount_desc: offlineCardDesc,
      logged_user_email: loggedUserEmail,
    };

    setOfflineCardPayLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordOfflineCardPayment/`,
        payload
      )
      .then((res) =>
        toast.success(
          `${t("Card payment of")} ${offlineCardAmt} ${t("recorded for")} ${
            reservationData?.booking_id
          }!`
        )
      )

      .then((res) => setOfflineCardPayLoading(false))
      .then((res) => setOfflineCardAmt(""))
      .then((res) => setOfflineCardDate(new Date()))
      .then((res) => setOfflineCardDesc())
      .then((res) => handleCloseOfflineCardPay())
      .catch((err) => setOfflineCardPayLoading(false))
      .catch((err) => handleCloseOfflineCardPay())
      .catch((err) => toast.error(err));
  };

  // handling the offline cheque payment flows

  const handleOfflineCheckPayment = () => {
    setOfflineCheckPayLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordOfflineCheckPayment/`,
        {
          hotelId: hotel?.hotelId,
          booking_id: reservationData?.booking_id,
          reservation_id: "",
          amount: offlineCheckAmt,
          offline_card_date: handleSentDateFormat(offlineCardDate),
          logged_user_email: loggedUserEmail,
          service_amount_desc: chequeDesc,
        }
      )
      .then((res) =>
        toast.success(
          `${t("Offline cheque payment of")} ${offlineCheckAmt} ${t(
            "recorded for"
          )} ${reservationData?.booking_id}!`
        )
      )

      .then((res) => setOfflineCheckPayLoading(false))
      .then((res) => setOfflineCheckAmt(""))
      .then((res) => setOfflineCheckDate(new Date()))
      .then((res) => setChequeDesc())
      .then((res) => handleCloseOfflineCheckPay())
      .catch((error) => toast.error(error))
      .then((res) => setOfflineCheckPayLoading(false))
      .then((res) => handleCloseOfflineCheckPay())
      .then((res) => setOfflineCheckAmt(""))
      .then((res) => setOfflineCheckDate(new Date()))
      .then((res) => setChequeDesc());
  };

  // paymentLink loading
  const [paymentLinkLoading, setPaymentLinkLoading] = useState(false);
  const [paymentLinkEmail, setPaymentLinkEmail] = useState(
    reservationData?.user_email
  );
  const [paymentLinkMobile, setPaymentLinkMobile] = useState(
    reservationData?.user_contact
  );
  const [paymentLinkAmt, setPaymentLinkAmt] = useState(
    reservationData?.balance_due
  );
  const [openOfflineCheckPay, setOpenOfflineCheckPay] = useState(false);
  const handleOpenOfflineCheckPay = () => setOpenOfflineCheckPay(true);

  // sending customer payment link
  function handlePaymentLink() {
    setPaymentLinkLoading(true);
    djangoHandleDataMutationRequest(
      "POST",
      "invoice/sendInvoicePaymentCustEmail/",
      {
        hotel_id: hotel?.hotelId,
        booking_id: reservationData?.booking_id,
        reservation_id: null,
        email: paymentLinkEmail,
        phone: paymentLinkMobile,
        amount: paymentLinkAmt,
        logged_user_email: loggedUserEmail,
      }
    )
      .then((res) =>
        toast.success(`${t("Payment link sent to the customer successfully.")}`)
      )

      .then((res) => setPaymentLinkLoading(false))
      .then((res) => handleCloseSendPaymentLink())
      .catch((err) => setPaymentLinkLoading(false))
      .catch((err) => toast.error(err));
  }

  const settleDuesComp = () => {
    return (
      <ClickAwayListenerHelper
        clickAwayButtonName="Settle dues"
        btnClassName="settleDuesBtnForModals"
        content={
          <div className="settleDuesActions">
            {JSON.parse(hotel?.accessControl?.user_feature_map?.CashPayment)
              .read === "YES" && (
              <div className="item" onClick={() => handleOpenCashPayment()}>
                Cash payment
              </div>
            )}
            {JSON.parse(
              hotel?.accessControl?.user_feature_map?.OfflineCardPayment
            ).read === "YES" && (
              <div className="item" onClick={() => handleOpenOfflineCardPay()}>
                Offline card payment
              </div>
            )}
            {JSON.parse(
              hotel?.accessControl?.user_feature_map?.OfflineChequePayment
            ).read === "YES" && (
              <div className="item" onClick={() => handleOpenOfflineCheckPay()}>
                Offline cheque payment
              </div>
            )}
            {hotelType !== "ISHA" && currency === "INR" && (
              <div className="item" onClick={() => handleOpenUpiPay()}>
                UPI Payment
              </div>
            )}

            {hotelType !== "ISHA" &&
              JSON.parse(
                hotel?.accessControl?.user_feature_map?.OtherPaymentModes
              ).read === "YES" && (
                <div className="item" onClick={() => handleOpenOtherModePay()}>
                  Other payment modes
                </div>
              )}

            {JSON.parse(
              hotel?.accessControl?.user_feature_map?.OtherPaymentModes
            ).read === "YES" && (
              <div
                className="item"
                onClick={() => handleOpenBankTransferModePay()}
              >
                Bank transfer
              </div>
            )}
            {hotelType !== "ISHA" &&
              JSON.parse(hotel?.accessControl?.user_feature_map?.CashDeposit)
                .read === "YES" && (
                <div className="item" onClick={() => handleOpenCashDeposit()}>
                  Cash deposit
                </div>
              )}
            {hotelType !== "ISHA" && (
              <div className="item" onClick={() => handleOpenSendPaymentLink()}>
                Send payment link
              </div>
            )}
            {/* {hotelType !== "ISHA" &&
              data?.accessControl?.accessUser?.accessmap
                ?.OfflineChequePayment && (
                <div
                  className="item"
                  onClick={() => handleOpenOfflineCheckPay()}
                >
                  Offline cheque payment
                </div>
              )} */}
            {/* {folioSummary.hotel.hotel_country === "India" &&
              hotelType !== "ISHA" && (
                <div className="item" onClick={() => handleOpenUpiPay()}>
                  UPI payment
                </div>
              )}
            {hotelType !== "ISHA" &&
              data?.accessControl?.accessUser?.accessmap?.OtherPaymentModes && (
                <div className="item" onClick={() => handleOpenOtherModePay()}>
                  Other payment modes
                </div>
              )}
            {hotelType !== "ISHA" &&
              data?.accessControl?.accessUser?.accessmap?.CashDeposit && (
                <div className="item" onClick={() => handleOpenCashDeposit()}>
                  Cash deposit
                </div>
              )} */}
            {/* <div className="horizontalSep" />

            <div className="horizontalSep" /> */}
          </div>
        }
        right="75px"
        bottom="-100px"
      />
    );
  };

  /* PRINT RESERVATION */
  // Modal for printing Registration card
  // Giving an option to print normal Registration card or empty res card for hotels to fill out the details
  const [openResCard, setOpenResCard] = useState(false);
  const handleOpenResCard = () => setOpenResCard(true);
  const handleCloseResCard = () => setOpenResCard(false);

  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      width: "50vw",
      backgroundColor: "#fff",
      height: "100%",
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
  });

  // Checkbox to determine whether to print with details of fill up the details on own
  const [manualResCard, setManualResCard] = useState(false);
  const handleChangeManualResCard = (e) => {
    setManualResCard(e.target.checked);
  };

  const [defaultCtadropdown, setDefaultdropdown] = useState([]);
  // handling the CTA dropdowns
  const [ctaDropdown, setCtaDropdown] = useState([]);
  function handleCtaDropdown() {
    // handleTrueWrapperMockLoader();
    setCtaDropdown([]);
    setDefaultdropdown([]);
    // if (Number(summaryData?.total_cta_balance) === 0) {
    // setCtaDropdown([
    //   {
    //     ctaName: "Walk-In",
    //     taxId: null,
    //   },
    // ]);
    djangoHandleDataRequests("invoice/getBookingSrcDropdownData/")
      .then((res) => {
        setDefaultdropdown(res);
        Number(summaryData?.total_cta_balance) === 0 && setCtaDropdown(res);
      })

      .catch((err) => toast.error(`${t("Error fethcing cta dropdown.")}`));
  }

  useEffect(() => {
    if (
      bookingData.bookingId !== null &&
      bookingData.bookingId !== undefined &&
      bookingData.bookingId !== ""
    ) {
      handleGetSummaryDataWithCache();
      handleCtaDropdown();
      getData();
      getCustomFolioConfig();
      getCompanyDetails();
    }
  }, [bookingData.bookingId]);

  const [invoiceData, setInvoiceData] = useState(null);

  // Get custom folio config
  const [customFolioConfig, setCustomFolioConfig] = useState(null);
  const getCustomFolioConfig = () => {
    djangoHandleDataRequests("invoice/getCustomFolioConfig/")
      .then((res) => setCustomFolioConfig(res !== null ? res : []))
      // .then((res) => fetchBookingData())
      .catch((err) =>
        toast.error(`${t("Error getting the folio config data")}`)
      );
  };

  const [allCompanyDetails, setallCompanyDetails] = useState([]);

  const getCompanyDetails = () => {
    handleSpringDataRequest(`core/api/v1/guest-company-details/get-all`)
      .then((res) => setallCompanyDetails(res))
      .catch((err) => console.error(err));
  };

  // Getting the invoice data for the first time ---  from the cache
  const getData = () => {
    handleSpringDataRequest(
      `core/api/v1/folio/invoice-data-render/?bookingId=${bookingData.bookingId}&fromDb=false`
    )
      .then((res) => {
        setInvoiceData(res);
      })
      // .then((res) => fetchBookingData())
      .catch((err) => toast.error(`${t("Error fetching the details")}`));
  };

  // Get the data for invoice summary
  const [summaryData, setSummaryData] = useState(null);
  const handleGetSummaryDataWithCache = () => {
    setSummaryData(null);
    handleSpringDataRequest(
      `core/api/v1/folio/invoice-summary-data/?bookingId=${bookingData.bookingId}&fromDB=false`
    )
      .then((res) => setSummaryData(res))

      // .then((res) => fetchBookingData())
      .catch((err) =>
        toast.error(`${t("Failed in getting the payment summary")}`)
      );
  };

  // Getting the hotel logo
  const [hotelLogo, sethotelLogo] = useState(null);
  const handleBase64Img = () => {
    handleSpringDataRequest("core/api/v1/image/get-hotel-base64-logo/")
      .then((res) => sethotelLogo(res))
      .catch((err) => toast.error(`${t("Error in fethcing the hotel logo!")}`));
  };

  // Getting the hotel logo
  useEffect(() => {
    summaryData && invoiceData && hotelLogo === null && handleBase64Img();
  }, [summaryData, invoiceData, hotelLogo, hotelId]);

  const [myCounter, setmyCounter] = useState(0);

  useEffect(() => {
    if (calendarApi && today && myCounter === 0) {
      calendarApi.gotoDate(today);
      let tempData = getParamsForView("date");
      fetchBookingDataWithParams(tempData);
      setmyCounter(1);
    }
  }, [today, calendarApi]);

  //-------------------Account Freeze---------------------

  const [freezed, setFreezed] = useState(sessionStorage.getItem("freezed"));
  useEffect(() => {
    setFreezed(sessionStorage.getItem("freezed"));
  }, [sessionStorage.getItem("freezed")]);

  return (
    <>
      <Grid
        container
        sx={{ padding: 2, "&:lastChild": 3 }}
        width="100%"
        height="100%"
        spacing={2}
      >
        <Grid item container xs={12} spacing={2}>
          <Grid
            item
            xs={2}
            sm={2}
            md={1}
            lg={1}
            xl={1}
            alignSelf="center"
            justifyContent="flex-start"
            container
          >
            <Grid
              item
              xs={12}
              style={{ display: "flex", alignItems: "center" }}
            >
              <IconButton
                color="primary"
                sx={{ overflow: "auto" }}
                style={{ border: "none" }}
                onClick={() => {
                  let tempData = getParamsForView("subtract");
                  fetchBookingDataWithParams(tempData);
                  calendarApi.prev();
                  setShowUnassigned(false);
                }}
              >
                <ArrowBackIosNew />
              </IconButton>

              <Switch checked={showRoomName} onChange={handleChangeName} />

              {/* <Dropdown
              drop="down"
              style={{ zIndex: "1000", marginLeft: "2rem" }}
            >
              <Dropdown.Toggle
                id="dropdown-toggle-my-cart-toggle"
                as={DropdownTopbarItemToggler}
              >
                <Tooltip title="Legends" arrow>
                  <IconButton>
                    <InfoIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
              </Dropdown.Toggle>

              <Dropdown.Menu
                className="p-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg panelWrapper"
                style={{
                  width: "22rem",
                  whiteSpace: "normal",
                  minHeight: "11rem",
                  maxHeight: "11rem",
                  overflowY: "auto",
                }}
              >
                <Dropdown.Item
                  style={{
                    whiteSpace: "normal",
                    margin: "0px",
                    padding: "0px",
                    paddingTop: "1rem",
                  }}
                >
                  <div className="d-flex  flex-column">
                    <div className="d-flex">
                      <BalanceIcon
                        style={{
                          color: "darkcyan",
                          marginRight: "1rem",
                          marginLeft: "1rem",
                        }}
                      />
                      <p>Balance due</p>
                    </div>
                    <div className="d-flex">
                      <SpeakerNotesIcon
                        style={{
                          color: "darkcyan",
                          marginRight: "1rem",
                          marginLeft: "1rem",
                        }}
                      />
                      <p>Booking notes</p>
                    </div>
                    <div className="d-flex">
                      <RadioButtonUncheckedIcon
                        style={{
                          color: "darkcyan",
                          marginRight: "1rem",
                          marginLeft: "1rem",
                        }}
                      />
                      <p>Self checkin requested</p>
                    </div>
                    <div className="d-flex">
                      <CheckCircleIcon
                        style={{
                          color: "darkcyan",
                          marginRight: "1rem",
                          marginLeft: "1rem",
                        }}
                      />
                      <p>Self checkin approved</p>
                    </div>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
            </Grid>
          </Grid>

          <Modal show={open} onHide={handleClose} style={{ zIndex: "3000" }}>
            <Modal.Header closeButton>
              <Modal.Title>
                {`${dirtyRoomId} ${t("Room is Dirty")}`}{" "}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>{t("Do you want to clean the room?")}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                {t("Close")}
              </Button>
              <Button variant="custom-button" onClick={handleCleanRoom}>
                {t("Clean room")}
              </Button>
            </Modal.Footer>
          </Modal>
          {freezed === "YES" && (
            <Box sx={{ zIndex: "3001" }}>
              <Freeze />
            </Box>
          )}
          <Modal show={freezed === "YES"} style={{ zIndex: "3000" }}>
            <Modal.Body></Modal.Body>
          </Modal>

          {/* <Modal
          show={openBookingModal}
          onHide={closeTheBookingModal}
          style={{ zIndex: "3000", marginTop: "13rem" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{`Booking is successfully created, booking id: ${bookingData.bookingId}`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-between">
              <Button
                onClick={() => handleOpenMagicLinkModal()}
                variant="custom-button"
              >
                Send magic link
              </Button>

              <Button
                onClick={() => handleOpenResCard()}
                variant="custom-button"
                style={{ marginRight: "1rem", marginLeft: "1rem" }}
              >
                Print Reservation Card
              </Button>
              <Link
                to={{
                  pathname: `/folio/${bookingData.bookingId}`,
                  search: `&hotelId=${hotel.hotelId}`,
                }}
                target="_blank"
              >
                <Button
                  // onClick={() => {
                  //   viewFolio(
                  //     "right",
                  //     true,
                  //     eventData.event.extendedProps.bookingId
                  //   );
                  // }}
                  style={{ marginRight: "1rem" }}
                  variant="custom-button"
                >
                  View folio
                </Button>
              </Link>
              {/* {hotelType !== "ISHA" && reservationData?.balance_due > 0 && (
                <div>{settleDuesComp()}</div>
              )} */}

          {/* settle dues --- offline card payment modal */}
          <Modal
            show={openOfflineCardPay}
            onHide={handleCloseOfflineCardPay}
            style={{ zIndex: "3000" }}
          >
            <Modal.Header closeButton>
              <Modal.Title> {t("Offline card payment")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="settleDuesWrapper">
                <div className="amountWrapper">
                  <div className="fieldWrapper">
                    <TextField
                      id="outlined-name"
                      label={t("Card amount")}
                      name={t("amount")}
                      className="mb-3"
                      value={offlineCardAmt}
                      onChange={(e) =>
                        /^[0-9.]*$/.test(e.target.value)
                          ? setOfflineCardAmt(e.target.value)
                          : setOfflineCardAmt(offlineCardAmt)
                      }
                      sx={{ width: "100%" }}
                      type={"text"}
                      disabled={
                        JSON.parse(
                          hotel?.accessControl?.user_feature_map
                            ?.EditBookingPrice
                        ).read !== "YES"
                      }
                    />
                    {/* <TextField
                      error
                      id="outlined-name"
                      label="Card amount"
                      name="amount"
                      value={offlineCardAmt}
                      onChange={(e) => (/^[0-9.]*$/.test(e.target.value) ? setOfflineCardAmt(e.target.value) : setOfflineCardAmt(offlineCardAmt))}
                      sx={{ width: "100%" }}
                      type={"text"}
                      helperText={"Please enter the positive value"}
                    /> */}
                  </div>
                  <div className="fieldWrapper">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        label={t("Date")}
                        value={offlineCardDate}
                        onChange={(newValue) => {
                          setOfflineCardDate(newValue);
                        }}
                        inputFormat="dd MMM yyyy"
                        name="offline_card_date"
                        renderInput={(params) => (
                          <TextField {...params} sx={{ width: "100%" }} />
                        )}
                      />
                    </LocalizationProvider>
                  </div>

                  <div className="fieldWrapper">
                    <TextField
                      id="outlined-name"
                      label={t("Amount description")}
                      className="mb-3 mt-3"
                      name="service_amount_desc"
                      value={serviceAmountDesc}
                      onChange={(e) => setOfflineCardDesc(e.target.value)}
                      sx={{ width: "100%" }}
                    />
                  </div>
                </div>

                <div
                  className="settleDuesSubmitBtns"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <button
                    className="cancelBtn"
                    onClick={() => handleCloseOfflineCardPay()}
                  >
                    {t("Cancel")}
                  </button>
                  {offlineCardPayLoading === false ? (
                    <>
                      {JSON.parse(
                        hotel?.accessControl?.user_feature_map?.EnquiryDashboard
                      ).read === "YES" ? (
                        <Button
                          className="submit"
                          variant="custom-button"
                          onClick={() => handleOfflineCardPayment()}
                          disabled={offlineCardAmt > 0 ? false : true}
                        >
                          {t("Submit")}
                        </Button>
                      ) : (
                        <Button
                          className="submit"
                          onClick={() => handleOfflineCardPayment()}
                          variant="custom-button"
                          disabled={offlineCardAmt > 0 ? false : true}
                        >
                          {t("Submit")}
                        </Button>
                      )}
                    </>
                  ) : (
                    <button className="submit">
                      <CircularProgress size="15px" />
                    </button>
                  )}
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* Modal to provide the type of res card that needs to be printed */}
          <Modal
            show={openResCard}
            onHide={handleCloseResCard}
            style={{ zIndex: "3000" }}
          >
            <Modal.Header closeButton>
              <Modal.Title> {t("Print Registration card")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="folioConfirmationWrapper">
                <div
                  className="folioContent"
                  style={{
                    borderBottom: "1px solid #DDDDDD",
                    paddingBottom: "15px",
                    marginBottom: "15px",
                  }}
                >
                  <Stack direction="row" spacing={3}>
                    <Checkbox
                      checked={manualResCard}
                      onChange={handleChangeManualResCard}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <div>
                      {t(
                        "I will fill up the details manually into the Registration card"
                      )}
                    </div>
                  </Stack>
                </div>
                <Stack direction="row">
                  <div className="updateBtn">
                    {manualResCard === true ? (
                      <div className="item" style={{ paddingTop: "10px" }}>
                        <PDFDownloadLink
                          document={
                            <Document title={bookingData.bookingId}>
                              <Page size="A4" style={styles.page} wrap>
                                <PrintEmptyResCard
                                  invoice={invoiceData?.invoice}
                                  symbol={currency}
                                  hotelData={invoiceData?.hotel}
                                  customDetails={customFolioConfig}
                                  hotelLogo={hotelLogo}
                                  summaryData={summaryData}
                                  hotelType={hotelType}
                                />
                              </Page>
                            </Document>
                          }
                          fileName={`${bookingData.bookingId}.pdf`}
                        >
                          {({ blob, url, loading, error }) =>
                            loading ? (
                              <p style={{ fontSize: "0.8rem" }}>
                                {t("Loading document...")}
                              </p>
                            ) : (
                              <a
                                href={url}
                                style={{ fontSize: "0.8rem" }}
                                rel={"noreferrer"}
                                target="_blank"
                              >
                                {window.screen.width > 416 && (
                                  <Button
                                    variant="custom-button"
                                    onClick={() =>
                                      setTimeout(() => {
                                        handleCloseResCard();
                                      }, 1500)
                                    }
                                  >
                                    {t("Print Registration card")}
                                  </Button>
                                )}
                              </a>
                            )
                          }
                        </PDFDownloadLink>
                      </div>
                    ) : (
                      <div className="item" style={{ paddingTop: "10px" }}>
                        <PDFDownloadLink
                          document={
                            <Document title={bookingData.bookingId}>
                              <Page size="A4" style={styles.page} wrap>
                                <PrintResCard
                                  allCompanyDetails={allCompanyDetails}
                                  invoice={invoiceData?.invoice}
                                  symbol={currency}
                                  hotelData={invoiceData?.hotel}
                                  customDetails={customFolioConfig}
                                  hotelLogo={hotelLogo}
                                  summaryData={summaryData}
                                  hotelType={hotelType}
                                  ctaDetails={
                                    defaultCtadropdown?.filter(
                                      (cta) =>
                                        cta?.cta_name ===
                                        invoiceData?.invoice?.booking_source
                                    )[0]
                                  }
                                  selectedLanguage={selectedLanguage}
                                />
                              </Page>
                            </Document>
                          }
                          fileName={`${bookingData.bookingId}.pdf`}
                        >
                          {({ blob, url, loading, error }) =>
                            loading ? (
                              <p style={{ fontSize: "0.8rem" }}>
                                {t("Loading document...")}
                              </p>
                            ) : (
                              <a
                                href={url}
                                style={{ fontSize: "0.8rem" }}
                                rel={"noreferrer"}
                                target="_blank"
                              >
                                {window.screen.width > 416 && (
                                  <Button
                                    variant="custom-button"
                                    onClick={() =>
                                      setTimeout(() => {
                                        handleCloseResCard();
                                      }, 1500)
                                    }
                                  >
                                    {t("Print Registration card")}
                                  </Button>
                                )}
                              </a>
                            )
                          }
                        </PDFDownloadLink>
                      </div>
                    )}
                  </div>
                </Stack>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            style={{ zIndex: "3000" }}
            show={openCashPayment}
            onHide={handleCloseCashPayment}
          >
            <Modal.Header closeButton>
              <Modal.Title> {t("Enter cash amount")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="settleDuesWrapper">
                <div className="settleDuesHeader">
                  {/* <div className="settleDuesHeading">Enter cash amount</div>
                <div className="close" onClick={() => handleCloseCashPayment()}>
                  X
                </div> */}
                </div>
                <div className="amountWrapper">
                  <div className="fieldWrapper">
                    <TextField
                      id="outlined-name"
                      label={t("Cash amount")}
                      name="amount"
                      value={amount}
                      className="mb-4"
                      onChange={(e) =>
                        /^[0-9.]*$/.test(e.target.value)
                          ? setAmount(e.target.value)
                          : setAmount(amount)
                      }
                      disabled={
                        JSON.parse(
                          hotel?.accessControl?.user_feature_map
                            ?.EditBookingPrice
                        ).read !== "YES"
                      }
                      sx={{ width: "100%" }}
                      type={"text"}
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  </div>
                  <div className="fieldWrapper">
                    <TextField
                      id="outlined-name"
                      label={t("Return to customer calculator")}
                      name="amount"
                      className="mb-4"
                      value={
                        Number(amount) >=
                        Number(reservationData.balance_due).toFixed(2)
                          ? (
                              Number(amount) -
                              Number(reservationData.balance_due).toFixed(2)
                            ).toFixed(2)
                          : `${t("Less amount")}`
                      }
                      sx={{ width: "100%" }}
                      type={"text"}
                      InputProps={{ inputProps: { min: 0 } }}
                      disabled
                    />
                  </div>
                  <div className="fieldWrapper">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        label={t("Date")}
                        value={offlineCashDate}
                        onChange={(newValue) => {
                          setOfflineCashDate(newValue);
                        }}
                        inputFormat="dd MMM yyyy"
                        name="offline_card_date"
                        renderInput={(params) => (
                          <TextField {...params} sx={{ width: "100%" }} />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="fieldWrapper">
                    <TextField
                      id="outlined-name"
                      label={t("Amount description")}
                      className="mb-4 mt-3"
                      name="service_amount_desc"
                      value={serviceAmountDesc}
                      onChange={(e) => setServiceAmountDesc(e.target.value)}
                      sx={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div
                  className="settleDuesSubmitBtns"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <button
                    className="cancelBtn"
                    onClick={() => handleCloseCashPayment()}
                  >
                    {t("Cancel")}
                  </button>
                  {cashPayLoading === false ? (
                    <Button
                      className="submit"
                      onClick={() => handleCashPayment()}
                      variant="custom-button"
                      disabled={
                        serviceAmountDesc !== ""
                          ? amount > 0
                            ? false
                            : true
                          : true
                      }
                    >
                      {t("Submit")}
                    </Button>
                  ) : (
                    <Button className="submit">
                      <CircularProgress size="15px" />
                    </Button>
                  )}
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* settle dues --- record payment from other sources */}
          <Modal
            show={openOtherModePay}
            onHide={handleCloseOtherModePay}
            style={{ zIndex: "3000" }}
          >
            <Modal.Header closeButton>
              <Modal.Title> {t("Other Payment modes")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="settleDuesWrapper">
                <div className="amountWrapper">
                  <div className="fieldWrapper">
                    <TextField
                      required
                      id="outlined-name"
                      label={t("Deposit amount")}
                      name="deposit_amount"
                      className="mb-3"
                      value={otherModeAmt}
                      onChange={(e) =>
                        /^[0-9.]*$/.test(e.target.value)
                          ? setOtherModeAmt(e.target.value)
                          : setOtherModeAmt(otherModeAmt)
                      }
                      sx={{ width: "100%" }}
                      type={"text"}
                    />
                  </div>
                  <div className="fieldWrapper">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        label={t("Service date")}
                        value={otherModeDate}
                        onChange={(newValue) => {
                          setOtherModeDate(newValue);
                        }}
                        inputFormat="dd MMM yyyy"
                        name="deposit_date"
                        renderInput={(params) => (
                          <TextField {...params} sx={{ width: "100%" }} />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="fieldWrapper">
                    <TextField
                      id="outlined-name"
                      label={t("Description")}
                      name="other_mode_desc"
                      className="mt-3 mb-3"
                      value={otherModeDesc}
                      onChange={(e) => setOtherModeDesc(e.target.value)}
                      sx={{ width: "100%" }}
                    />
                  </div>
                  <div
                    className="settleDuesSubmitBtns"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      className="cancelBtn"
                      onClick={() => handleCloseOtherModePay()}
                    >
                      {t("Cancel")}
                    </Button>
                    {otherModePayLoading === false ? (
                      <Button
                        className="submit"
                        variant="custom-button"
                        onClick={() => handleOtherModePayment()}
                        disabled={otherModeAmt > 0 ? false : true}
                      >
                        {t("Submit")}
                      </Button>
                    ) : (
                      <Button className="submit">
                        <CircularProgress size="15px" />
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* settle dues --- Bank Transfer */}
          <Modal
            show={openBankTransferModePay}
            onHide={handleCloseBankTransferModePay}
            style={{ zIndex: "3000" }}
          >
            <Modal.Header closeButton>
              <Modal.Title> {t("Enter bank transfer amount")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="settleDuesWrapper">
                <div className="amountWrapper">
                  <div className="fieldWrapper">
                    <TextField
                      id="outlined-name"
                      label={t("Amount")}
                      name="amount"
                      value={bankTransferModeAmt}
                      onChange={(e) =>
                        /^[0-9.]*$/.test(e.target.value)
                          ? setBankTransferModeAmt(e.target.value)
                          : setBankTransferModeAmt(bankTransferModeAmt)
                      }
                      sx={{ width: "100%", marginBottom: "1rem" }}
                      type={"text"}
                    />
                  </div>
                  <div className="fieldWrapper">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        label={t("Date")}
                        value={bankTransferModeDate}
                        onChange={(newValue) => {
                          setBankTransferModeDate(newValue);
                        }}
                        inputFormat="dd MMM yyyy"
                        name="upi_date"
                        renderInput={(params) => (
                          <TextField {...params} sx={{ width: "100%" }} />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="fieldWrapper">
                    <TextField
                      id="outlined-name"
                      label={t("Remarks")}
                      name="description"
                      value={bankTransferModeDesc}
                      onChange={(e) => setBankTransferModeDesc(e.target.value)}
                      sx={{
                        width: "100%",
                        marginBottom: "1rem",
                        marginTop: "1rem",
                      }}
                    />
                  </div>
                </div>
                <div className="settleDuesSubmitBtns">
                  {bankTransferModePayLoading === false ? (
                    <Button
                      className="submit"
                      variant="custom-button"
                      onClick={() => handleBankTransferPayment()}
                      disabled={bankTransferModeAmt > 0 ? false : true}
                    >
                      {t("Submit")}
                    </Button>
                  ) : (
                    <Button className="submit">
                      <CircularProgress size="15px" />
                    </Button>
                  )}
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* settle dues --- cash deposit modal */}
          <Modal
            show={openCashDeposit}
            onHide={handleCloseCashDeposit}
            style={{ zIndex: "3000" }}
          >
            <Modal.Header closeButton>
              <Modal.Title> {t("Cash Deposit")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="settleDuesWrapper">
                <div className="amountWrapper">
                  <div className="fieldWrapper">
                    <TextField
                      required
                      id="outlined-name"
                      label={t("Cash deposit amount*")}
                      name="deposit_amount"
                      className="mb-3"
                      value={depositAmt}
                      onChange={(e) =>
                        /^[0-9.]*$/.test(e.target.value)
                          ? setDepositAmt(e.target.value)
                          : setDepositAmt(depositAmt)
                      }
                      sx={{ width: "100%" }}
                      type={"text"}
                    />
                  </div>
                  <div className="fieldWrapper">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        label={t("Service date")}
                        value={depositDate}
                        onChange={(newValue) => {
                          setDepositDate(newValue);
                        }}
                        inputFormat="dd MMM yyyy"
                        name="deposit_date"
                        renderInput={(params) => (
                          <TextField {...params} sx={{ width: "100%" }} />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="fieldWrapper">
                    <TextField
                      id="outlined-name"
                      label={t("Description")}
                      className="mb-3 mt-3"
                      name="folio_item_name"
                      value={depositName}
                      onChange={(e) => setDepositName(e.target.value)}
                      sx={{ width: "100%" }}
                    />
                  </div>
                  <div
                    className="settleDuesSubmitBtns"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      className="cancelBtn"
                      onClick={() => handleCloseCashDeposit()}
                    >
                      {t("Cancel")}
                    </Button>
                    {depositLoading === false ? (
                      <Button
                        className="submit"
                        variant="custom-button"
                        onClick={() => handleDeposit()}
                        disabled={depositAmt > 0 ? false : true}
                      >
                        {t("Submit")}
                      </Button>
                    ) : (
                      <Button className="submit">
                        <CircularProgress size="15px" />
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* settle dues --- record UPI payment modal */}
          <Modal
            show={openUpi}
            onHide={handleCloseUpiPay}
            style={{ zIndex: "3000" }}
          >
            <Modal.Header closeButton>
              <Modal.Title> {t("UPI Payment")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="settleDuesWrapper">
                <div className="amountWrapper">
                  <div className="fieldWrapper">
                    <TextField
                      id="outlined-name"
                      label={t("UPI Payment")}
                      name="amount"
                      className="mb-3"
                      value={upiAmt}
                      onChange={(e) =>
                        /^[0-9.]*$/.test(e.target.value)
                          ? setUpiAmt(e.target.value)
                          : setUpiAmt(upiAmt)
                      }
                      sx={{ width: "100%" }}
                      type={"text"}
                    />
                  </div>
                  <div className="fieldWrapper">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        label={t("Date")}
                        value={upiDate}
                        onChange={(newValue) => {
                          setUpiDate(newValue);
                        }}
                        inputFormat="dd MMM yyyy"
                        name="upi_date"
                        renderInput={(params) => (
                          <TextField {...params} sx={{ width: "100%" }} />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="fieldWrapper">
                    <TextField
                      id="outlined-name"
                      label={t("Description")}
                      name="description"
                      className="mb-3 mt-3"
                      value={upiDesc}
                      onChange={(e) => setUpiDesc(e.target.value)}
                      sx={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div
                  className="settleDuesSubmitBtns"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    className="cancelBtn"
                    onClick={() => handleCloseOfflineCheckPay()}
                  >
                    {t("Cancel")}
                  </Button>
                  {upiPayLoading === false ? (
                    <Button
                      className="submit"
                      variant="custom-button"
                      onClick={() => handleUpiPayment()}
                      disabled={upiAmt > 0 ? false : true}
                    >
                      {t("Submit")}
                    </Button>
                  ) : (
                    <Button className="submit">
                      <CircularProgress size="15px" />
                    </Button>
                  )}
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* settle dues --- paymentLink */}
          <Modal
            show={openSendPaymentLink}
            onHide={handleCloseSendPaymentLink}
            style={{ zIndex: "3000" }}
          >
            <Modal.Header closeButton>
              <Modal.Title> {t("Send Payment Link")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="settleDuesWrapper">
                <div className="amountWrapper">
                  <div className="fieldWrapper">
                    <TextField
                      id="outlined-name"
                      label={t("Email")}
                      name="email"
                      className="mb-3"
                      helperText={`${t(
                        "By default customer email has been populated. Override if necessary."
                      )}`}
                      value={paymentLinkEmail}
                      onChange={(e) => setPaymentLinkEmail(e.target.value)}
                      sx={{ width: "100%" }}
                    />
                  </div>
                  <div className="fieldWrapper">
                    <TextField
                      id="outlined-name"
                      label={t("Mobile number")}
                      name="mobile"
                      className="mb-3"
                      helperText={`${t(
                        "By default customer mobile has been populated. Override if necessary."
                      )}`}
                      value={paymentLinkMobile}
                      onChange={(e) => setPaymentLinkMobile(e.target.value)}
                      sx={{ width: "100%" }}
                    />
                  </div>
                  <div className="fieldWrapper">
                    <TextField
                      id="outlined-name"
                      label={t("Amount")}
                      className="mb-3"
                      helperText={`${t(
                        "By default total due amount has been populated. Override if necessary."
                      )}`}
                      name="service_amount_desc"
                      value={paymentLinkAmt}
                      onChange={(e) => setPaymentLinkAmt(e.target.value)}
                      sx={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div
                  className="settleDuesSubmitBtns"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button className="cancelBtn">Cancel</Button>
                  {paymentLinkLoading === false && hotelType !== "ISHA" ? (
                    <Button
                      variant="custom-button"
                      className="submit w-auto"
                      onClick={() => handlePaymentLink()}
                    >
                      {t("Send Payment Link")}
                    </Button>
                  ) : (
                    <Button className="submit">
                      <CircularProgress size="15px" />
                    </Button>
                  )}
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* settle dues --- offline cheque payment modal */}
          <Modal
            show={openOfflineCheckPay}
            onHide={handleCloseOfflineCheckPay}
            style={{ zIndex: "3000" }}
          >
            <Modal.Header closeButton>
              <Modal.Title> {t("Offline Cheque Payment")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="settleDuesWrapper">
                <div className="amountWrapper">
                  <div className="fieldWrapper">
                    <TextField
                      id="outlined-name"
                      label={t("Cheque amount")}
                      name="amount"
                      className="mb-3"
                      value={offlineCheckAmt}
                      onChange={(e) =>
                        /^[0-9.]*$/.test(e.target.value)
                          ? setOfflineCheckAmt(e.target.value)
                          : setOfflineCheckAmt(offlineCheckAmt)
                      }
                      sx={{ width: "100%" }}
                      type={"text"}
                    />
                  </div>
                  <div className="fieldWrapper">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        label={t("Date")}
                        value={offlineCheckDate}
                        onChange={(newValue) => {
                          setOfflineCheckDate(newValue);
                        }}
                        inputFormat="dd MMM yyyy"
                        name="offline_check_date"
                        renderInput={(params) => (
                          <TextField {...params} sx={{ width: "100%" }} />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="fieldWrapper">
                    <TextField
                      id="outlined-name"
                      label={t("Description")}
                      className="mb-3 mt-3"
                      name="description"
                      value={chequeDesc}
                      onChange={(e) => setChequeDesc(e.target.value)}
                      sx={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div
                  className="settleDuesSubmitBtns"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    className="cancelBtn"
                    onClick={() => handleCloseOfflineCheckPay()}
                  >
                    {t("Cancel")}
                  </Button>
                  {offlineCheckPayLoading === false ? (
                    <Button
                      className="submit"
                      variant="custom-button"
                      onClick={() => handleOfflineCheckPayment()}
                      disabled={offlineCheckAmt > 0 ? false : true}
                    >
                      {t("Submit")}
                    </Button>
                  ) : (
                    <Button className="submit">
                      <CircularProgress size="15px" />
                    </Button>
                  )}
                </div>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            show={openMagicLinkModal}
            onHide={handleCloseMagicLinkModal}
            style={{ zIndex: "3000" }}
          >
            <Modal.Header closeButton>
              <div className="d-flex flex-column">
                <Modal.Title> {t("Send magic link")}</Modal.Title>
                <p className="text-muted mb-0">
                  {t(
                    "Magic Link connects your guests to your property in real time. They can view the itinerary, perform self check-ins, room upgrades, stay extensions, and add-on purchases."
                  )}
                </p>
              </div>
            </Modal.Header>
            <Modal.Body>
              <div
                className="fieldWrapper"
                style={{
                  position: "relative",
                  width: "100%",
                  fontSize: "12px",
                  fontWeight: 500,
                  backgroundColor: "#C8E5F3",
                  padding: "5px",
                  borderRadius: "5px",
                  color: "black",
                }}
              >
                {envType.toLowerCase() === "prod"
                  ? `https://selfservice.stayflexi.com/booking/${bookingData.bookingId}`
                  : `https://beta.selfservice.stayflexi.com/booking/${bookingData.bookingId}`}
              </div>
              <br></br>
              <div className="fieldWrapper">
                <TextField
                  id="outlined-name"
                  label={t("Email")}
                  name="email"
                  helperText={`${t(
                    "By default customer email has been populated. Override if necessary."
                  )}`}
                  value={magicLinkMail}
                  onChange={(e) => setMagicLinkMail(e.target.value)}
                  sx={{ width: "100%" }}
                />
              </div>
              <br></br>
              <div className="fieldWrapper">
                <TextField
                  id="outlined-name"
                  label={t("Phone number")}
                  name="phone"
                  helperText={`${t(
                    "By default customer contact number has been populated. Override if necessary."
                  )}`}
                  value={magicLinkPhone}
                  onChange={(e) => {
                    /^[\+0-9]*$/.test(e.target.value) &&
                      setMagicLinkPhone(e.target.value);
                  }}
                  sx={{ width: "100%" }}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="submit w-auto"
                variant="custom-button"
                onClick={() => handleSendMagicLink()}
                disabled={magicLinkLoading}
              >
                {magicLinkLoading ? (
                  <CircularProgress
                    size={15}
                    color="inherit"
                    sx={{ ml: 1.5 }}
                  />
                ) : (
                  `${t("Send Magic Link")}`
                )}
              </Button>
            </Modal.Footer>
          </Modal>

          <Grid item container xs={8} sm={8} md={7} lg={7} xl={7} spacing={2}>
            {
              <Grid
                container
                justifyContent="flex-start"
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
              >
                <FormControl fullWidth>
                  <InputLabel>{t("Filters")}</InputLabel>
                  <Select
                    value={selectedRoom}
                    multiple
                    size="small"
                    onChange={(eve) => {
                      let val = eve.target.value;
                      const all = val.some((e) => e.id === "All");
                      const exist_all = selectedRoom.some(
                        (e) => e.id === "All"
                      );
                      if (exist_all && all && val.length > 1) {
                        val = val.filter((e) => e.id !== "All");
                      }
                      if (all && !exist_all) {
                        val = rooms.filter((e) => e.id === "All");
                      }
                      if (val.some((e) => e.text === "Unselect")) {
                        val = [];
                      }
                      // if (val.length === rooms.length - 2) {
                      //   val = rooms.filter((e) => e.id === "All")
                      // }
                      setSelectedRoom(val);
                      let tempData = getParamsForView("date");
                      const temporary = [];
                      if (val.length === 0 || val.some((e) => e.id === "All")) {
                        tempData.roomTypes = rooms.forEach((room) => {
                          if (
                            room.id !== "All" &&
                            room.id !== "Unselect" &&
                            room.id !== "availableRooms" &&
                            room.id !== "blockedRooms" &&
                            room.id !== "dirtyRooms" &&
                            room.id !== "cleanRooms" &&
                            room.id !== "bookedRooms"
                          )
                            temporary.push(room.id);
                        });
                      } else {
                        val.forEach((room) => {
                          temporary.push(room.id);
                        });
                        tempData.roomTypes = temporary.filter(
                          (item) =>
                            item !== "availableRooms" &&
                            item !== "blockedRooms" &&
                            item !== "dirtyRooms" &&
                            item !== "cleanRooms" &&
                            item !== "bookedRooms"
                        );
                      }

                      tempData.availableRooms =
                        temporary.includes("availableRooms");
                      tempData.blockedRooms =
                        temporary.includes("blockedRooms");
                      tempData.dirtyRooms = temporary.includes("dirtyRooms");
                      tempData.cleanRooms = temporary.includes("cleanRooms");
                      tempData.bookedRooms = temporary.includes("bookedRooms");
                      fetchBookingDataWithParams(tempData);
                    }}
                    label={t("Filters")}
                  >
                    {/* <MenuItem value={{ text: "All", id: "All" }} > All </MenuItem>
                  <MenuItem value={{ text: "Unselect", id: "Unselect" }} > Unselect all </MenuItem> */}

                    {rooms.slice(0, 5).map((room, index) => {
                      return (
                        <MenuItem key={room.text} value={room}>
                          {room.text}
                        </MenuItem>
                      );
                    })}
                    {rooms.length > 5 && <Divider />}
                    {rooms.slice(5, rooms.length).map((room, index) => (
                      <MenuItem key={room.text} value={room}>
                        {room.text}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            }
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              container
              justifyContent="center"
              alignSelf="center"
              style={{ marginLeft: "6rem" }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={today}
                  onChange={(newValue) => {
                    setToday(newValue);
                    calendarApi.gotoDate(newValue);
                    let tempData = getParamsForView("date");
                    fetchBookingDataWithParams(tempData);
                    setShowUnassigned(false);
                  }}
                  renderInput={({ inputRef, inputProps, InputProps }) => (
                    // <Box sx={{ display: "flex", alignItems: "center" }}>
                    //   <Typography variant="h5">
                    //     {checkForEmptyCalendar()
                    //       ? calendarApi.view.title
                    //       : moment(new Date()).format("MMMM D, YYYY")}
                    //   </Typography>

                    //   <div ref={inputRef} {...inputProps}>
                    //     {InputProps?.endAdornment}
                    //   </div>
                    // </Box>

                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <div ref={inputRef} {...inputProps}>
                        {/* Render the calendar icon with increased size */}
                        {InputProps?.endAdornment && (
                          <Box sx={{ fontSize: 32 }}>
                            {InputProps.endAdornment}
                          </Box>
                        )}
                      </div>
                    </Box>
                  )}
                ></DatePicker>
              </LocalizationProvider>
              {/* {unassingedCount > 0 && (
               
                <Tooltip
                  arrow
                  title={`${unassingedCount} ${t(
                    "Unassigned and Hold reservations"
                  )} `}
                >
                  {unassingedCount > 0 && (
                    <Typography
                      sx={{ padding: 2, cursor: "pointer" }}
                      onClick={() => {
                        setUnassignedDate(getStartEndDates());
                        setShowUnassigned(true);
                      }}
                    >
                      {`${t("Unassigned")} (${unassingedCount})`}
                    </Typography>
                  )}
                </Tooltip>
              )} */}
              <ButtonGroup
                sx={{
                  overflow: "auto",
                  paddingLeft: 2,
                  paddingRight: 3,
                }}
                orientation={matches ? "horizontal" : "vertical"}
                size={matches ? "medium" : "small"}
              >
                <Button
                  sx={{
                    textTransform: "none",
                    fontWeight: "bold",
                    fontSize: "1rem",
                    backgroundColor: `${
                      dashboardView === "fullView" ? "#36454F" : "white"
                    }`,
                    color: `${
                      dashboardView === "fullView" ? "white" : "#36454F"
                    }`,
                    borderColor: "#36454F",
                  }}
                  variant={
                    dashboardView === "fullView" ? "contained" : "outlined"
                  }
                  onClick={() => {
                    sessionStorage.setItem("fullView", true);
                    setDashboardView("fullView");
                  }}
                >
                  {`${t("Full view")}`}
                </Button>

                {showRoomView && (
                  <Button
                    sx={{
                      textTransform: "none",
                      fontWeight: "bold",
                      fontSize: "1rem",
                      backgroundColor: `${
                        dashboardView === "roomView" ? "#36454F" : "white"
                      }`,
                      color: `${
                        dashboardView === "roomView" ? "white" : "#36454F"
                      }`,
                      borderColor: "#36454F",
                    }}
                    variant={
                      dashboardView === "roomView" ? "contained" : "outlined"
                    }
                    onClick={() => {
                      sessionStorage.setItem("fullView", false);
                      setDashboardView("roomView");
                    }}
                  >
                    {`${t("Room view")}`}
                  </Button>
                )}
              </ButtonGroup>
            </Grid>
          </Grid>
          <Grid
            spacing={2}
            item
            xs={2}
            sm={2}
            md={2}
            lg={4}
            xl={4}
            alignSelf="center"
            justifyContent="flex-end"
            container
          >
            <Grid item container justifyContent="flex-end" xs={12}>
              {unassingedCount > 0 && (
                <Tooltip
                  arrow
                  title={`${unassingedCount} ${t(
                    "Unassigned and Hold reservations"
                  )} `}
                >
                  {unassingedCount > 0 && (
                    <span className="menu-text">
                      <Badge
                        badgeContent={unassingedCount}
                        sx={{
                          "& .MuiBadge-badge": {
                            backgroundColor: "#F64E60",
                            color: "white",
                          },
                        }}
                      >
                        <Button
                          style={{
                            borderRadius: "50px",
                            backgroundColor: "#36454F",
                            color: "white",
                          }}
                          variant="contained"
                          onClick={() => {
                            setUnassignedDate(getStartEndDates());
                            setShowUnassigned(true);
                          }}
                        >
                          UA
                        </Button>
                      </Badge>
                    </span>
                  )}
                </Tooltip>
              )}
              <FormControl
                fullWidth
                size="small"
                style={{ width: "6rem", marginLeft: "1rem" }}
              >
                <InputLabel
                  style={{ width: "6rem" }}
                  id="demo-simple-select-label"
                >
                  {t("Create")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={(e) => {
                    setMultiOption(e.target.value);
                    setMenuOpen(false);
                  }}
                  style={{ marginLeft: "0rem", width: "6rem" }}
                  className="mr-4"
                  label={t("Create")}
                  size="small"
                  value={multiOption}
                >
                  <MenuItem value={"walkin"}> {t("Walk-in")}</MenuItem>
                  <MenuItem value={"enquiry"}>{t("Enquiry")}</MenuItem>
                  {(hotel?.accessControl?.ADMINRIGHTS === "ADMIN" ||
                    hotel?.accessControl?.ADMINRIGHTS === "SUBADMIN" ||
                    (hotelemail !== "reservation@maharashtratourism.gov.in" &&
                      JSON.parse(
                        hotel?.accessControl?.user_feature_map?.HideBlockRooms
                      ).read !== "YES")) && (
                    <MenuItem value={"block"}>{t("Block Room")}</MenuItem>
                  )}
                  <MenuItem value={"group"}>{t("Group booking")}</MenuItem>
                </Select>
              </FormControl>
              {/* <IconButton
              sx={{ paddingRight: 1 }}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setMenuOpen(true);
              }}
            >
              <MoreVert fontSize="large" />
            </IconButton>
            <Popper
              style={{ zIndex: 4 }}
              open={menuOpen}
              anchorEl={anchorEl}
              placement="bottom-end"
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: (placement = "bottom"),
                  }}
                >
                  <Paper>
                    <ClickAwayListener
                      onClickAway={() => {
                        setMenuOpen(false);
                      }}
                    >
                      <MenuList
                        id="composition-menu"
                        key="first-item"
                        aria-labelledby="composition-button"
                      >
                        {Object.keys(multiOptions).map((item) => (
                          <MenuItem
                            key={item}
                            selected={item === multiOption}
                            onClick={() => {
                              setMultiOption(item);
                              setMenuOpen(false);
                            }}
                          >
                            {multiOptions[item]}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper> */}
              <ButtonGroup
                sx={{ overflow: "auto", paddingLeft: 2, paddingRight: 3 }}
                orientation={matches ? "horizontal" : "vertical"}
                size={matches ? "medium" : "small"}
              >
                <Button
                  sx={{ textTransform: "none" }}
                  style={{
                    backgroundColor: `${
                      checkForEmptyCalendar() &&
                      calendarApi.view.type === "resourceTimelineDay"
                        ? "#36454F"
                        : "white"
                    }`,
                    color: `${
                      checkForEmptyCalendar() &&
                      calendarApi.view.type === "resourceTimelineDay"
                        ? "white"
                        : "#36454F"
                    }`,
                    borderColor: "#36454F",
                  }}
                  variant={
                    checkForEmptyCalendar() &&
                    calendarApi.view.type === "resourceTimelineDay"
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => {
                    sessionStorage.setItem("types", "day");
                    calendarApi.changeView("resourceTimelineDay");
                    let tempData = getParamsForView("day");
                    fetchBookingDataWithParams(tempData, "day");
                  }}
                >
                  {t("Day")}
                </Button>
                <Button
                  sx={{ textTransform: "none" }}
                  style={{
                    backgroundColor: `${
                      checkForEmptyCalendar() &&
                      calendarApi.view.type === "resourceTimelineWeek"
                        ? "#36454F"
                        : "white"
                    }`,
                    color: `${
                      checkForEmptyCalendar() &&
                      calendarApi.view.type === "resourceTimelineWeek"
                        ? "white"
                        : "#36454F"
                    }`,
                    borderColor: "#36454F",
                  }}
                  variant={
                    checkForEmptyCalendar() &&
                    calendarApi.view.type === "resourceTimelineWeek"
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => {
                    sessionStorage.setItem("types", "week");
                    calendarApi.changeView("resourceTimelineWeek");
                    let tempData = getParamsForView("week");
                    fetchBookingDataWithParams(tempData, "week");
                  }}
                >
                  {t("Week")}
                </Button>
                <Button
                  sx={{ textTransform: "none" }}
                  style={{
                    backgroundColor: `${
                      checkForEmptyCalendar() &&
                      calendarApi.view.type === "resourceTimelineMonthCustom"
                        ? "#36454F"
                        : "white"
                    }`,
                    color: `${
                      checkForEmptyCalendar() &&
                      calendarApi.view.type === "resourceTimelineMonthCustom"
                        ? "white"
                        : "#36454F"
                    }`,
                    borderColor: "#36454F",
                  }}
                  variant={
                    checkForEmptyCalendar() &&
                    calendarApi.view.type === "resourceTimelineMonthCustom"
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => {
                    sessionStorage.setItem("types", "month");
                    calendarApi.changeView("resourceTimelineMonthCustom");
                    let tempData = getParamsForView("month");
                    fetchBookingDataWithParams(tempData, "month");
                  }}
                >
                  {t("Month")}
                </Button>
              </ButtonGroup>

              <IconButton
                color="primary"
                onClick={() => {
                  let tempData = getParamsForView("add");
                  fetchBookingDataWithParams(tempData);
                  calendarApi.next();
                  setShowUnassigned(false);
                }}
              >
                <ArrowForwardIos />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item display={"inline-flex"} xs={12} height="100%">
          {loading && (
            <Grid
              item
              xs={12}
              position={"fixed"}
              top="50%"
              left="50%"
              container
            >
              <DimmingDots />
            </Grid>
          )}
          <div style={{ width: "100%" }} className="demo-app-main">
            <FullCalendar
              // nowIndicator={true}
              refetchResourcesOnNavigate={true}
              eventResizableFromStart={true}
              resourceAreaWidth={100}
              schedulerLicenseKey="0974064232-fcs-1639721704"
              selectOverlap={hotel.slotType === "PURE_HOURLY"}
              views={{
                resourceTimelineWeek: {
                  nowIndicator: true,
                  titleFormat: { month: "short", day: "2-digit" },
                  contentHeight: "auto",
                  height: "auto",
                  selectOverlap: hotel.slotType === "PURE_HOURLY",
                  slotLabelDidMount: (el) => {
                    if (
                      el.level === 1 ||
                      el.text === "12am" ||
                      el.text === "8am" ||
                      el.text === "4pm"
                    ) {
                      el.el.className = el.el.className + " dont-display";
                    }
                    if (el.level === 0) {
                      el.el.className = el.el.className + " special-display";
                      el.el.childNodes[0].className =
                        el.el.childNodes[0].className + " spe-height";
                    }
                  },
                  slotLabelWillUnmount: (el) => {
                    if (
                      el.level === 1 ||
                      el.text === "12am" ||
                      el.text === "8am" ||
                      el.text === "4pm"
                    ) {
                      el.el.className = el.el.className + " dont-display";
                    }
                    if (el.level === 0) {
                      el.el.className = el.el.className + " special-display";
                      el.el.childNodes[0].className =
                        el.el.childNodes[0].className + " spe-height";
                    }
                  },
                  slotMinTime: "00:00:00",
                  slotMaxTime: "24:00:00",
                  slotDuration:
                    hotel.slotType === "NOON" ? Noonslot : hotelSlot,
                  //   ? "23:00:00" : hourToString[initialData.checkInTimes[initialData.checkInTimes.length - 1]].text,
                  slotLabelContent: (day) => {
                    if (day.level === 0) {
                      return (
                        <span>
                          <Tooltip
                            arrow
                            title={`Go to ${day.date.toDateString()}`}
                          >
                            <Typography
                              onClick={(e) => {
                                e.stopPropagation();
                                calendarApi.changeView("resourceTimelineDay");
                                setToday(day.date);
                                calendarApi.gotoDate(day.date);
                                let tempData = getParamsForView("date");
                                fetchBookingDataWithParams(tempData);
                              }}
                              sx={{
                                color: "#2C3E50 !important",
                                display: "flex",
                                paddingRight: "4px",
                                fontWeight: 600,
                              }}
                              component={"div"}
                              color={"inherit"}
                            >
                              {day.date.toDateString()}
                            </Typography>
                          </Tooltip>
                          {/* {
                          (!isUndefined(unAssigned[moment(day.date).format(InventoryDateformat)]) &&
                            unAssigned[moment(day.date).format(InventoryDateformat)] !== 0) &&
                          <Tooltip title={`${unAssigned[moment(day.date).format(InventoryDateformat)]} Unassigned Reservations`}
                            arrow>
                            < Typography sx={{ textDecoration: "underline" }}
                              onClick={async (e) => {
                                e.stopPropagation()
                                setUnassignedDate(day.date)
                                setShowUnassigned(true)
                              }}>
                              {`(${unAssigned[moment(day.date).format(InventoryDateformat)]})`}
                            </Typography>
                          </Tooltip>
                        } */}
                        </span>
                      );
                    } else {
                      return (
                        <Typography sx={{ color: "#2C3E50 !important" }}>
                          {day.text}
                        </Typography>
                      );
                    }
                  },
                  eventDidMount: (eve) => {
                    if (
                      eve.event.extendedProps.status === "CONFIRMED" ||
                      eve.event.extendedProps.status === "ADMIN_CONFIRMED"
                    ) {
                      var div = document.createElement("div");
                      div.className = "fc-event-resizer fc-event-resizer-start";
                      var div1 = document.createElement("div");
                      div.className = "fc-event-resizer fc-event-resizer-end";
                      eve.el.appendChild(div);
                      eve.el.appendChild(div1);
                    }
                  },
                  eventContent: (arg) => {
                    const start = moment(
                      arg.event._instance.range.start
                    ).format("DD-MM-YYYY");
                    const end = moment(arg.event._instance.range.end).format(
                      "DD-MM-YYYY"
                    );

                    let days = moment(arg.event._instance.range.end).diff(
                      moment(arg.event._instance.range.start),
                      "days"
                    );

                    let len = arg.event.title.length;
                    let finalTital;
                    if (days === 0 && len > 10) {
                      finalTital = arg.event.title.substring(0, 6) + "...";
                    } else if (days === 1 && len > 30) {
                      finalTital = arg.event.title.substring(0, 25) + "...";
                    } else if (days === 2 && len > 45) {
                      finalTital = arg.event.title.substring(0, 40) + "...";
                    } else {
                      finalTital = arg.event.title;
                    }
                    return (
                      <div>
                        {/* <LightTooltip
                        // title={<CustomPopper htmlEle={arg} />}
                        arrow
                      > */}
                        <div
                        // style={{
                        //   textDecoration:
                        //     arg.event.extendedProps.status ===
                        //       "CHECKED_OUT" ||
                        //     arg.event.extendedProps.status === "DONE"
                        //       ? "line-through black"
                        //       : "none",
                        // }}
                        >
                          <div
                            style={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              fontWeight: 600,
                              fontSize: "1rem",
                              paddingLeft: "1rem",
                              color: "#2C3F51",
                            }}
                            // className={
                            //   // arg.event.extendedProps.status ===
                            //   //   "CHECKED_OUT" ||
                            //   // // arg.event.extendedProps.status === "CONFIRMED" ||
                            //   // arg.event.extendedProps.status === "DONE"
                            //   //   ? "calendar-text"
                            //   //   : null
                            // }
                          >
                            {arg.event.extendedProps.locked_status ===
                              "LOCKED" && (
                              <Lock
                                color={
                                  arg.event.extendedProps.status ===
                                    "CHECKED_OUT" ||
                                  arg.event.extendedProps.status ===
                                    "CONFIRMED" ||
                                  arg.event.extendedProps.status === "DONE"
                                    ? "primary"
                                    : "inherit"
                                }
                                sx={{ marginRight: 1 }}
                              />
                            )}
                            {/* {arg.event.title} */}
                            {finalTital}

                            {
                              whiteLabelled === "ENABLED"
                                ? getSource(
                                    arg.event.extendedProps.bookingSource
                                  )?.includes(".png") &&
                                  !whitlableArrMap.includes(
                                    arg.event.extendedProps.bookingSource
                                  ) && (
                                    <img
                                      src={getSource(
                                        arg.event.extendedProps.bookingSource
                                      )}
                                      style={{
                                        height: "2rem",
                                        marginLeft: "10px",
                                        borderRadius: "100%",
                                      }}
                                    />
                                  )
                                : getSource(
                                    arg.event.extendedProps.bookingSource
                                  )?.includes(".png") && (
                                    <img
                                      src={getSource(
                                        arg.event.extendedProps.bookingSource
                                      )}
                                      style={{
                                        height: "2rem",
                                        marginLeft: "10px",
                                        borderRadius: "100%",
                                      }}
                                    />
                                  )
                              // <img
                              //   src={getSource(
                              //     arg.event.extendedProps.bookingSource
                              //   )}
                              //   style={{
                              //     height: "2rem",
                              //     marginLeft: "10px",
                              //     borderRadius: "100%",
                              //   }}
                              // />
                            }
                            {arg.event.extendedProps.balanceDue > 0 && (
                              <BalanceIcon
                                style={{
                                  marginLeft: "10px",
                                  color: arg.event.extendedProps
                                    .highBalanceDueAlertStatus
                                    ? "red"
                                    : "darkcyan",
                                }}
                              />
                            )}
                            {arg.event.extendedProps.bookingNotes === "YES" && (
                              <SpeakerNotesIcon
                                style={{
                                  marginLeft: "10px",
                                  color: "darkcyan",
                                }}
                              />
                            )}
                            {arg.event.extendedProps.groupBooking === true && (
                              <GroupsIcon
                                style={{
                                  marginLeft: "10px",
                                  color: "darkcyan",
                                }}
                              />
                            )}

                            {arg.event.extendedProps.selfServiceStatus ===
                              "REQUESTED" && (
                              <RadioButtonUncheckedIcon
                                style={{
                                  marginLeft: "10px",
                                  color: "darkcyan",
                                }}
                              />
                            )}

                            {arg.event.extendedProps.selfServiceStatus ===
                              "APPROVED" && (
                              <CheckCircleIcon
                                style={{
                                  marginLeft: "10px",
                                  color: "darkcyan",
                                }}
                              />
                            )}
                          </div>
                        </div>
                        {/* </LightTooltip> */}
                      </div>
                    );
                  },
                },
                resourceTimelineDay: {
                  nowIndicator: true,
                  titleFormat: { month: "short", day: "2-digit" },
                  contentHeight: "auto",
                  height: "auto",
                  selectOverlap: false,
                  slotDuration: "01:00:00",
                  slotLabelContent: (data) => {
                    return (
                      <Typography
                        sx={{ color: "#2C3E50 !important", fontWeight: 600 }}
                      >
                        {data.text}
                      </Typography>
                    );
                  },
                  slotLaneContent: (day) => {
                    return (
                      <div>
                        <Typography>{day.time}</Typography>
                      </div>
                    );
                  },
                  eventContent: (arg) => {
                    return (
                      // <LightTooltip
                      //   // title={<CustomPopper inter htmlEle={arg} />}
                      //   arrow
                      // >
                      <div
                      // style={{
                      //   textDecoration:
                      //     // arg.event.extendedProps.status === "CHECKED_OUT" ||
                      //     // arg.event.extendedProps.status === "DONE"
                      //     //   ? "line-through black"
                      //     //   : "none",
                      // }}
                      >
                        <div
                          style={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            fontWeight: 600,
                            fontSize: "1rem",
                            paddingLeft: "1rem",
                            color: "#2C3F51",
                          }}
                          // className={
                          //   // arg.event.extendedProps.status === "CHECKED_OUT" ||
                          //   // arg.event.extendedProps.status === "CONFIRMED" ||
                          //   // arg.event.extendedProps.status === "DONE"
                          //   //   ? "calendar-text"
                          //   //   : null
                          // }
                        >
                          {arg.event.extendedProps.locked_status ===
                            "LOCKED" && (
                            <Lock
                              color={
                                arg.event.extendedProps.status ===
                                  "CHECKED_OUT" ||
                                arg.event.extendedProps.status ===
                                  "CONFIRMED" ||
                                arg.event.extendedProps.status === "DONE"
                                  ? "primary"
                                  : "inherit"
                              }
                              sx={{ marginRight: 1 }}
                            />
                          )}
                          {arg.event.title}

                          {
                            whiteLabelled === "ENABLED"
                              ? getSource(
                                  arg.event.extendedProps.bookingSource
                                )?.includes(".png") &&
                                !whitlableArrMap.includes(
                                  arg.event.extendedProps.bookingSource
                                ) && (
                                  <img
                                    src={getSource(
                                      arg.event.extendedProps.bookingSource
                                    )}
                                    style={{
                                      height: "2rem",
                                      marginLeft: "10px",
                                      borderRadius: "100%",
                                    }}
                                  />
                                )
                              : getSource(
                                  arg.event.extendedProps.bookingSource
                                )?.includes(".png") && (
                                  <img
                                    src={getSource(
                                      arg.event.extendedProps.bookingSource
                                    )}
                                    style={{
                                      height: "2rem",
                                      marginLeft: "10px",
                                      borderRadius: "100%",
                                    }}
                                  />
                                )
                            // <img
                            //   src={getSource(
                            //     arg.event.extendedProps.bookingSource
                            //   )}
                            //   style={{
                            //     height: "2rem",
                            //     marginLeft: "10px",
                            //     borderRadius: "100%",
                            //   }}
                            // />
                          }
                          {arg.event.extendedProps.balanceDue > 0 && (
                            <BalanceIcon
                              style={{
                                marginLeft: "10px",
                                color: arg.event.extendedProps
                                  .highBalanceDueAlertStatus
                                  ? "red"
                                  : "darkcyan",
                              }}
                            />
                          )}
                          {arg.event.extendedProps.bookingNotes === "YES" && (
                            <SpeakerNotesIcon
                              style={{ marginLeft: "10px", color: "darkcyan" }}
                            />
                          )}
                          {arg.event.extendedProps.groupBooking === true && (
                            <GroupsIcon
                              style={{
                                marginLeft: "10px",
                                color: "darkcyan",
                              }}
                            />
                          )}

                          {arg.event.extendedProps.selfServiceStatus ===
                            "REQUESTED" && (
                            <RadioButtonUncheckedIcon
                              style={{ marginLeft: "10px", color: "darkcyan" }}
                            />
                          )}
                          {arg.event.extendedProps.selfServiceStatus ===
                            "APPROVED" && (
                            <CheckCircleIcon
                              style={{ marginLeft: "10px", color: "darkcyan" }}
                            />
                          )}
                        </div>
                      </div>
                      // </LightTooltip>
                    );
                  },
                },
                resourceTimelineMonthCustom: {
                  nowIndicator: true,
                  titleFormat: { month: "short", day: "2-digit" },
                  contentHeight: "auto",
                  height: "auto",
                  type: "resourceTimeline",
                  selectOverlap: hotel.slotType === "PURE_HOURLY",
                  eventMaxStack: 2,
                  dayCount: 30,
                  duration: { days: 30 },
                  slotLabelContent: (day) => {
                    return (
                      <div style={{ width: "100%" }}>
                        {day.level === 0 && (
                          <span style={{ display: "flex" }}>
                            <Tooltip
                              title={`Go to ${day.date.toDateString()}`}
                              arrow
                            >
                              <Typography
                                onClick={(e) => {
                                  e.stopPropagation();
                                  calendarApi.changeView("resourceTimelineDay");
                                  setToday(day.date);
                                  calendarApi.gotoDate(day.date);
                                  let tempData = getParamsForView("date");
                                  fetchBookingDataWithParams(tempData);
                                }}
                                sx={{
                                  color: "#2C3E50 !important",
                                  display: "flex",
                                  paddingRight: "4px",
                                  fontWeight: 600,
                                }}
                                component={"div"}
                              >
                                {day.text}
                              </Typography>
                            </Tooltip>
                          </span>
                        )}
                      </div>
                    );
                  },
                  eventContent: (arg) => {
                    return (
                      // <LightTooltip title={<CustomPopper htmlEle={arg} />} arrow>
                      <div
                      // style={{
                      //   textDecoration:
                      //     // arg.event.extendedProps.status === "CHECKED_OUT" ||
                      //     // arg.event.extendedProps.status === "DONE"
                      //     //   ? "line-through black"
                      //     //   : "none",
                      // }}
                      >
                        <div
                          style={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            fontWeight: 600,
                            fontSize: "1rem",
                            paddingLeft: "1rem",
                            color: "#2C3F51",
                          }}
                          // className={

                          //     // arg.event.extendedProps.status === "CHECKED_OUT" ||
                          //     // arg.event.extendedProps.status === "CONFIRMED" ||
                          //     // arg.event.extendedProps.status === "DONE"
                          //     //   ? "calendar-text"
                          //     //   : null

                          // }
                        >
                          {arg.event.extendedProps.locked_status ===
                            "LOCKED" && (
                            <Lock
                              color={
                                arg.event.extendedProps.status ===
                                  "CHECKED_OUT" ||
                                arg.event.extendedProps.status ===
                                  "CONFIRMED" ||
                                arg.event.extendedProps.status === "DONE"
                                  ? "primary"
                                  : "inherit"
                              }
                              sx={{ marginRight: 1 }}
                            />
                          )}
                          {arg.event.title}

                          {
                            whiteLabelled === "ENABLED"
                              ? getSource(
                                  arg.event.extendedProps.bookingSource
                                )?.includes(".png") &&
                                !whitlableArrMap.includes(
                                  arg.event.extendedProps.bookingSource
                                ) && (
                                  <img
                                    src={getSource(
                                      arg.event.extendedProps.bookingSource
                                    )}
                                    style={{
                                      height: "2rem",
                                      marginLeft: "10px",
                                      borderRadius: "100%",
                                    }}
                                  />
                                )
                              : getSource(
                                  arg.event.extendedProps.bookingSource
                                )?.includes(".png") && (
                                  <img
                                    src={getSource(
                                      arg.event.extendedProps.bookingSource
                                    )}
                                    style={{
                                      height: "2rem",
                                      marginLeft: "10px",
                                      borderRadius: "100%",
                                    }}
                                  />
                                )
                            // <img
                            //   src={getSource(
                            //     arg.event.extendedProps.bookingSource
                            //   )}
                            //   style={{
                            //     height: "2rem",
                            //     marginLeft: "10px",
                            //     borderRadius: "100%",
                            //   }}
                            // />
                          }
                          {arg.event.extendedProps.balanceDue > 0 && (
                            <BalanceIcon
                              style={{
                                marginLeft: "10px",
                                color: arg.event.extendedProps
                                  .highBalanceDueAlertStatus
                                  ? "red"
                                  : "darkcyan",
                              }}
                            />
                          )}
                          {arg.event.extendedProps.bookingNotes === "YES" && (
                            <SpeakerNotesIcon
                              style={{ marginLeft: "10px", color: "darkcyan" }}
                            />
                          )}
                          {arg.event.extendedProps.groupBooking === true && (
                            <GroupsIcon
                              style={{
                                marginLeft: "10px",
                                color: "darkcyan",
                              }}
                            />
                          )}
                          {arg.event.extendedProps.selfServiceStatus ===
                            "REQUESTED" && (
                            <RadioButtonUncheckedIcon
                              style={{ marginLeft: "10px", color: "darkcyan" }}
                            />
                          )}
                          {arg.event.extendedProps.selfServiceStatus ===
                            "APPROVED" && (
                            <CheckCircleIcon
                              style={{ marginLeft: "10px", color: "darkcyan" }}
                            />
                          )}
                        </div>
                      </div>
                      // </LightTooltip>
                    );
                  },
                },
              }}
              droppable={false}
              contentHeight={"auto"}
              height="auto"
              scrollTime={"00:00"}
              forceEventDuration={true}
              firstDay={
                checkForEmptyCalendar()
                  ? calendarApi.getDate().getDay()
                  : new Date().getDay()
              }
              lazyFetching={true}
              plugins={[interactionPlugin, resourceTimelinePlugin]}
              headerToolbar={false}
              // drop={(eve) => {
              //   setRoomTypeObj({
              //     name: eve.resource.extendedProps.roomType,
              //     id: eve.resource.id,
              //     roomTypeId: eve.resource.extendedProps.roomTypeId,
              //   });
              // }}
              // eventReceive={(eve) => {
              //   setShowModal(eve);
              //   setShow(true);
              //   setActionType("externalDrop");
              // }}
              // dayHeaderContent ={(day) => {
              //   return(
              //     <div>{day.date.toISOString()}</div>
              //   )
              // }}
              // resourceLabelDidMount={(eve) => {
              //   if (eve.resource.extendedProps.dirtyColor !== null) {
              //     eve.el.className = eve.el.className + " fc-room-dirty"
              //   }
              // }}
              resourceLabelContent={(arg) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      cursor: "pointer",
                      color: `${
                        showRoomName
                          ? arg.resource.extendedProps.roomTypeColor ===
                            "#ffffff"
                            ? "black"
                            : arg.resource.extendedProps.roomTypeColor
                          : "black"
                      }`,
                    }}
                    onClick={(e) => {
                      setdirtyRoomId(arg.resource.title);
                      if (
                        arg.resource.extendedProps.dirtyColor === "bg-danger"
                      ) {
                        handleOpen();
                      }
                    }}
                  >
                    <div
                      style={{
                        width: `${
                          arg.resource.extendedProps.dirtyColor === "bg-danger"
                            ? "1em"
                            : "1rem"
                        }`,
                        marginRight: "10px",
                      }}
                    >
                      {arg.resource.extendedProps.dirtyColor ===
                        "bg-danger" && (
                        // !(
                        //   arg.resource.extendedProps.dirtyColor !== undefined ||
                        //   arg.resource.extendedProps.dirtyColor !== null
                        // )
                        <FiberManualRecord
                          color="error"
                          fontSize="small"
                          sx={{ ml: 0.5, alignSelf: "center" }}
                        />
                      )}
                    </div>

                    <LightTooltip
                      title={arg.resource.extendedProps.roomType}
                      arrow
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        {arg.resource.title}
                      </Typography>
                    </LightTooltip>

                    {showRoomName && (
                      <LightTooltip
                        title={arg.resource.extendedProps.roomType}
                        arrow
                      >
                        <Typography sx={{ ml: 0.5, fontWeight: "bold" }}>
                          {` - ${arg.resource.extendedProps.roomTypeCode}`}
                        </Typography>
                      </LightTooltip>
                    )}
                  </div>
                );
              }}
              eventResizeStart={() => {
                setActionType("extend");
              }}
              eventDragStart={() => {
                setActionType("DragorDrop");
              }}
              initialView={
                hotel.slotType === "PURE_HOURLY"
                  ? "resourceTimelineDay"
                  : "resourceTimelineWeek"
              }
              initialDate={
                hotel.hotelCurrency === "INR"
                  ? new Date().toISOString()
                  : new Date(Date.now() - 3600 * 1000 * 24).toISOString()
              }
              selectable={true}
              events={initialData.bookingData}
              eventClick={handleDateSelect}
              resources={roomNumbers}
              resourceOrder={function (a, b) {
                return parseInt(a.initalId) < parseInt(b.initalId)
                  ? -1
                  : a.initalId > b.initalId
                  ? 1
                  : 0;
              }}
              stickyHeaderDates={"auto"}
              eventOverlap={true}
              select={addNew}
              ref={calendarRef}
              eventChange={handleDragStop}
              resourceAreaHeaderContent={"Rooms"}
              eventClassNames={(arg) => {
                if (arg.event.extendedProps.enquiry === "true") {
                  return ["fc-enquiry-event"];
                } else {
                  if (arg.event.extendedProps.status === "CONFIRMED") {
                    return ["fc-confirmed-event"];
                  }

                  if (arg.event.extendedProps.status === "CHECKED_OUT") {
                    return ["fc-checkedout-event"];
                  }

                  if (arg.event.extendedProps.status === "DONE") {
                    return ["fc-checkedout-event"];
                  }
                  if (arg.event.extendedProps.status === "BLOCKED")
                    return ["fc-blocked-event"];

                  if (arg.event.extendedProps.status === "COMBO")
                    return ["fc-combo-room"];
                  return ["timeline-calendar"];
                }
              }}
            />
            <div>
              {show && actionType === "add" && showModal.resource ? (
                <ModalView
                  viewType={calendarApi.view.type}
                  reload={fetchBookingDataWithoutLoad}
                  reloadagain={reloadData}
                  calendarApi={calendarApi}
                  eventType={actionType}
                  eventData={showModal}
                  open={show}
                  handleAddEventSidebar={handleAddEventSidebar}
                  addEvent={setNewData}
                  roomInformation={
                    initialData.roomTypeInformation[
                      showModal?.resource?.extendedProps?.roomTypeId.split(
                        ","
                      )[0]
                    ]
                  }
                  openTheBookingModal={openTheBookingModal}
                  selectedLanguage={selectedLanguage}
                ></ModalView>
              ) : null}
              {openBookingModal && (
                <BookingModal
                  open={openBookingModal}
                  close={closeTheBookingModal}
                  bookingData={bookingData}
                  invoiceData={invoiceData}
                  customFolioConfig={customFolioConfig}
                  currency={currency}
                  allCompanyDetails={allCompanyDetails}
                  summaryData={summaryData}
                  hotelType={hotelType}
                  hotelLogo={hotelLogo}
                  defaultCtadropdown={defaultCtadropdown}
                  reservationData={reservationData}
                  reload={fetchBookingDataWithoutLoad}
                  selectedLanguage={selectedLanguage}
                />
              )}
              {show && actionType === "DragorDrop" ? (
                <DragModalView
                  viewType={calendarApi.view.type}
                  reload={fetchBookingDataWithoutLoad}
                  calendarApi={calendarApi}
                  eventType={actionType}
                  eventData={showModal}
                  open={show}
                  handleAddEventSidebar={modify}
                  addEvent={changeDateTime}
                  moveEvent={moveToNewRoom}
                  roomInformation={originalAllRoomInfo}
                  roomTypeInfo={initialData.roomTypeInformation}
                  edit={"edit"}
                  selectedLanguage={selectedLanguage}
                ></DragModalView>
              ) : null}
              {show && actionType === "extend" ? (
                <ExtendModalView
                  viewType={calendarApi.view.type}
                  eventData={showModal}
                  open={show}
                  handleAddEventSidebar={modify}
                  addEvent={changeDateTime}
                  reload={fetchBookingDataWithoutLoad}
                  selectedLanguage={selectedLanguage}
                ></ExtendModalView>
              ) : null}
              {show && actionType === "edit" ? (
                <EditModalView
                  viewType={calendarApi.view.type}
                  reload={fetchBookingDataWithoutLoad}
                  lockEvent={lockEvent}
                  removeEvent={removeEvent}
                  calendarApi={calendarApi}
                  eventType={actionType}
                  eventData={showModal}
                  open={show}
                  // viewFolio={(one, two, three) => {
                  //   toggleDrawer(one, two, three);
                  // }}
                  handleAddEventSidebar={handleAddEventSidebar}
                  addEvent={handleAddEventSidebar}
                  roomInformation={originalAllRoomInfo}
                  roomTypeInfo={initialData.roomTypeInformation}
                  setShowModal={setShowModal}
                  setActionType={setActionType}
                  setShow={setShow}
                  selectedLanguage={selectedLanguage}
                ></EditModalView>
              ) : null}
              {/* {show && actionType === "externalDrop" && (
              <ReassignView
                viewType={calendarApi.view.type}
                refetch={(resId) => {
                  removeFromUnassigned(resId);
                  setShow(false);
                  showModal.event.remove();
                  setShowModal(null);
                  calendarApi.refetchEvents();
                  fetchBookingDataWithoutLoad();
                }}
                calendarApi={calendarApi}
                eventData={showModal}
                open={show}
                roomInfo={roomTypeObj}
                handleAddEventSidebar={() => {
                  showModal.event.remove();
                  setShowModal(null);
                  setShow(false);
                  setRoomTypeObj({});
                }}
                addEvent={changeDateTime}
              />
            )} */}
              {show &&
                actionType === "edit-blocked" &&
                (hotel?.accessControl?.ADMINRIGHTS === "ADMIN" ||
                  hotel?.accessControl?.ADMINRIGHTS === "SUBADMIN" ||
                  JSON.parse(
                    hotel?.accessControl?.user_feature_map?.HideUnBlockRooms
                  ).read !== "YES") && (
                  <UnblockView
                    reload={fetchBookingDataWithoutLoad}
                    type="unblock"
                    eventData={showModal}
                    open={show}
                    closeFunc={handleAddEventSidebar}
                    roomInformation={originalAllRoomInfo}
                    roomTypeInfo={initialData.roomTypeInformation}
                    selectedLanguage={selectedLanguage}
                  ></UnblockView>
                )}
            </div>
            <Drawer
              anchor={"right"}
              open={multiOption === "group"}
              onClose={() => {
                setMultiOption("");
              }}
              className={classes.drawer}
              classes={{
                paper: classes.drawerPaper,
              }}
              transitionDuration={{ appear: 500, enter: 500, exit: 500 }}
            >
              <MultipleBookingView
                calendarApi={calendarApi}
                hotelId={hotelId}
                reload={fetchBookingDataWithoutLoad}
                onClose={() => {
                  setMultiOption("");
                }}
                className={classes.drawer}
                classes={{
                  paper: classes.drawerPaper,
                }}
                viewType={calendarApi?.view?.type}
                transitionDuration={{ appear: 500, enter: 500, exit: 500 }}
                selectedLanguage={selectedLanguage}
              ></MultipleBookingView>
            </Drawer>

            <Drawer
              anchor={"right"}
              open={multiOption === "enquiry"}
              onClose={() => {
                setMultiOption("");
              }}
              className={classes.drawers}
              classes={{
                paper: classes.drawerPapers,
              }}
              transitionDuration={{ appear: 500, enter: 500, exit: 500 }}
            >
              <NewEnquiry
                calendarApi={calendarApi}
                hotelId={hotelId}
                reload={fetchBookingDataWithoutLoad}
                onClose={() => {
                  setMultiOption("");
                }}
                className={classes.drawers}
                classes={{
                  paper: classes.drawerPapers,
                }}
                addEvent={setNewData}
                viewType={calendarApi?.view?.type}
                transitionDuration={{ appear: 500, enter: 500, exit: 500 }}
                selectedLanguage={selectedLanguage}
              ></NewEnquiry>
            </Drawer>

            <Drawer
              anchor={"right"}
              open={multiOption === "walkin"}
              onClose={() => {
                setMultiOption("");
              }}
              className={classes.drawer}
              classes={{
                paper: classes.drawerPaper,
              }}
              transitionDuration={{ appear: 500, enter: 500, exit: 500 }}
            >
              <Walkin
                calendarApi={calendarApi}
                hotelId={hotelId}
                reload={fetchBookingDataWithoutLoad}
                onClose={() => {
                  setMultiOption("");
                }}
                className={classes.drawer}
                classes={{
                  paper: classes.drawerPaper,
                }}
                addEvent={setNewData}
                viewType={calendarApi?.view?.type}
                transitionDuration={{ appear: 500, enter: 500, exit: 500 }}
                openTheBookingModal={openTheBookingModal}
                selectedLanguage={selectedLanguage}
              ></Walkin>
            </Drawer>
            <Drawer
              anchor={"right"}
              open={multiOption === "block"}
              onClose={() => {
                setMultiOption("");
              }}
              className={classes.drawers}
              classes={{
                paper: classes.drawerPapers,
              }}
              transitionDuration={{ appear: 500, enter: 500, exit: 500 }}
            >
              <BlockRoom
                calendarApi={calendarApi}
                hotelId={hotelId}
                reload={fetchBookingDataWithoutLoad}
                onClose={() => {
                  setMultiOption("");
                }}
                className={classes.drawers}
                classes={{
                  paper: classes.drawerPapers,
                }}
                addEvent={setNewData}
                viewType={calendarApi?.view?.type}
                transitionDuration={{ appear: 500, enter: 500, exit: 500 }}
                selectedLanguage={selectedLanguage}
              ></BlockRoom>
            </Drawer>
            {/* <Drawer
            anchor={"right"}
            open={state["right"]}
            onClose={() => {
              toggleDrawer("right", false);
            }}
          >
            {folioDrawer("right")}
          </Drawer> */}
          </div>
          {/* {showUnassigned && (
            <div
              style={{
                width: "30%",
                maxHeight: "100%",
                padding: "0px 16px 16px 16px",
              }}
            >
              <Slide in>
                <UnassignedView
                  data={initialData}
                  date={unassignedDate}
                  closeFunc={() => {
                    setShowUnassigned(false);
                  }}
                  reload={fetchBookingDataWithoutLoad}
                  selectedLanguage={selectedLanguage}
                ></UnassignedView>
              </Slide>
            </div>
          )} */}

          <Drawer
            anchor={"right"}
            open={showUnassigned}
            onClose={() => {
              setShowUnassigned(false);
            }}
            className={classes.drawers}
            classes={{
              paper: classes.drawerPapers,
            }}
            transitionDuration={{ appear: 500, enter: 500, exit: 500 }}
          >
            <UnassignedView
              data={initialData}
              date={unassignedDate}
              closeFunc={() => {
                setShowUnassigned(false);
              }}
              reload={fetchBookingDataWithoutLoad}
              selectedLanguage={selectedLanguage}
            ></UnassignedView>
          </Drawer>
          {/* <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={snackbar}
        autoHideDuration={6000} onClose={() => {
          setSnackbar(false)
        }}>
        <Alert onClose={() => {
          setSnackbar(false)
        }} severity="success">
          Booking Successful
        </Alert>
      </Snackbar> */}
        </Grid>
      </Grid>
    </>
  );
};

function mapStateToProps(state) {
  return { datass: state.auth.selectedHotel, hotelType: state.auth.hotelType };
}
export default connect(mapStateToProps)(DemoApp);
